import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  convertSocketChatDataToReduxChatData,
  publishOrderChatMessage,
} from "utils/helper-functions/redis-helper";
import { STORE_NAMES } from "utils/constants/redux";
import {
  generateDynamicWSEventName,
  getRedisWebsocket,
  REDIS_CHANNELS,
  REDIS_STREAM_CONSTANTS,
} from "utils/api/redis";
import {
  setOrReplaceOrder,
  setOrReplaceSelectedOrder,
} from "redux/slices/ordersStore";
import { addPartialMenuUpdate } from "redux/slices/menuStore";
import { addMessagesToOrder, addMessageToOrder } from "redux/slices/chatStore";
import { setOrReplaceReservations } from "redux/slices/reservationStore";
import { setOrReplaceBusiness } from "redux/slices/businessStore";

const AdminWebsocketContext = createContext({});

const AdminWebsocketContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const businessId = useSelector(
    (state) => state[STORE_NAMES.user].user?.business?.id
  );
  const user = useSelector((state) => state[STORE_NAMES.user].user);
  const socket = useMemo(() => getRedisWebsocket(), [businessId, user?.id]);
  // handle admin join and subscribe
  useEffect(() => {
    if (socket && businessId && user) {
      // join order channel
      socket.emit(REDIS_CHANNELS.adminOrder.join, {
        businessId,
        user,
      });
      // subscribe to messages
      socket.on(
        generateDynamicWSEventName(
          REDIS_CHANNELS.adminOrder.inboundEventName,
          businessId
        ),
        (payloadString) => {
          const payload = JSON.parse(payloadString);
          if (businessId === payload.businessId) {
            if (payload.data) {
              if (payload.data.reservations) {
                dispatch(setOrReplaceReservations(payload.data.reservations));
              }
              if (payload.data.orders) {
                dispatch(setOrReplaceOrder(payload.data.orders));
                dispatch(setOrReplaceSelectedOrder(payload.data.orders));
              }
              if (payload.data.menu) {
                dispatch(addPartialMenuUpdate(payload.data.menu));
              }
              if (payload.data.business) {
                dispatch(setOrReplaceBusiness(payload.data.business));
              }
            }
            // if (payload.message) {
            //   toast.success(payload.message);
            // }
          }
        }
      );

      // Join Business Chat channel
      socket.emit(REDIS_STREAM_CONSTANTS.subscribes.joinBusinessChatChannel, {
        businessId: businessId,
        userId: user.id,
      });

      socket.on(REDIS_STREAM_CONSTANTS.events.newChatMessage, (data) => {
        const reduxConsumableData = convertSocketChatDataToReduxChatData(data);
        dispatch(addMessageToOrder(reduxConsumableData));
      });

      socket.on(
        REDIS_STREAM_CONSTANTS.events.oldBusinessOrderMessages,
        (data) => {
          const topics = data.reduce((acc, orderOldChats) => {
            if (orderOldChats.length > 0) {
              const messages = orderOldChats.map(
                (chat) => convertSocketChatDataToReduxChatData(chat).message
              );
              const topic = {
                id: orderOldChats[0].orderId,
                orderId: orderOldChats[0].orderId,
                businessId: orderOldChats[0].businessId,
                messages,
                usersLastReadTime: [],
              };
              acc.push(topic);
            }
            return acc;
          }, []);

          dispatch(addMessagesToOrder(topics));
        }
      );
    }

    return () => {
      socket.disconnect();
    };
  }, [socket, businessId, user?.id]);

  const publishTableChatMessage = (data) => {
    if (socket && data.orderId) {
      publishOrderChatMessage(socket, data);
    }
  };

  // Provide the context value to the children
  return (
    <AdminWebsocketContext.Provider value={{ publishTableChatMessage }}>
      {children}
    </AdminWebsocketContext.Provider>
  );
};

AdminWebsocketContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AdminWebsocketContextProvider, AdminWebsocketContext };
