import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { convertMeterToPixel } from "../helper";
import { STORE_NAMES } from "utils/constants/redux";
import AdminMapViewTable from "pages/admin/admin-pages/admin-map/admin-map-view/admin-map-view-table/AdminMapViewTable";

import "./AdminMapView.scss";

export const INITIAL_SIZE_CONTAINER = { width: 0, height: 0 };
export const INITIAL_SIZE_ZONE = { width: 10, height: 5 };

const ORDER_MAP_BORDER_WIDTH = 10;
const ORDER_MAP_EXTERNAL_PADDING = 24;

const AdminMapView = ({ onClickTable, activeTablesId, selectedZone }) => {
  const adminMapContainerRef = useRef(null);
  const [size, setSize] = useState(INITIAL_SIZE_CONTAINER);
  const [ratio, setRatio] = useState(0);
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);

  const originalSizeOfZone = useMemo(
    () => ({
      width: selectedZone?.width || INITIAL_SIZE_ZONE.width,
      height: selectedZone?.height || INITIAL_SIZE_ZONE.height,
    }),
    [selectedZone]
  );

  const mapItems = useMemo(() => {
    if (selectedZone) {
      return selectedZone.tables
        .filter((table) =>
          Object.values(table.coordinates).every((value) => value !== null)
        )
        .map((table) => ({
          id: String(table.id),
          name: table.name,
          maxSeat: table.maxSeat,
          shape: table.shape,
          ...table.coordinates,
        }));
    }
    return [];
  }, [selectedZone]);

  const findRatio = useCallback(
    ({ containerSize }) => {
      const { width: originalWidth } = originalSizeOfZone;
      const { width: containerWidth } = containerSize;
      setRatio(containerWidth / originalWidth);
    },
    [originalSizeOfZone]
  );

  const handleResize = useCallback(
    (entry) => {
      const updatedSizeOfContainer = {
        width:
          entry.contentRect.width -
          (ORDER_MAP_BORDER_WIDTH + ORDER_MAP_EXTERNAL_PADDING) * 2,
        height:
          entry.contentRect.height -
          (ORDER_MAP_BORDER_WIDTH + ORDER_MAP_EXTERNAL_PADDING) * 2,
      };
      setSize(updatedSizeOfContainer);
      findRatio({ containerSize: updatedSizeOfContainer });
    },
    [findRatio]
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        handleResize(entry);
      }
    });

    if (adminMapContainerRef.current) {
      resizeObserver.observe(adminMapContainerRef.current);
      const initialSize = {
        width:
          adminMapContainerRef.current.clientWidth -
          (ORDER_MAP_BORDER_WIDTH + ORDER_MAP_EXTERNAL_PADDING) * 2,
        height:
          adminMapContainerRef.current.clientHeight -
          (ORDER_MAP_BORDER_WIDTH + ORDER_MAP_EXTERNAL_PADDING) * 2,
      };
      setSize(initialSize);
      findRatio({ containerSize: initialSize });
    }

    return () => {
      if (adminMapContainerRef.current) {
        resizeObserver.unobserve(adminMapContainerRef.current);
      }
    };
  }, [handleResize, findRatio]);

  const orderInTable = useCallback(
    (tableId) => orders.find((order) => order.table.id === Number(tableId)),
    [orders]
  );

  const displayedData = useMemo(() => {
    if (size && ratio && mapItems.length) {
      return mapItems
        .map((item) => ({
          ...convertMeterToPixel({ item, ratio }),
          id: parseInt(item.id),
        }))
        .filter((table) => activeTablesId.includes(table.id));
    }
    return [];
  }, [size, ratio, mapItems, activeTablesId]);

  return (
    <div className="AdminMapView" ref={adminMapContainerRef}>
      <div
        className="AdminMapViewContainer"
        style={{
          width: `${
            originalSizeOfZone.width * ratio + ORDER_MAP_BORDER_WIDTH * 2
          }px`,
          height: `${
            originalSizeOfZone.height * ratio + ORDER_MAP_BORDER_WIDTH * 2
          }px`,
          borderWidth: `${ORDER_MAP_BORDER_WIDTH}px`,
        }}
      >
        <div
          className="AdminMapViewContent"
          style={{
            width: `${originalSizeOfZone.width * ratio}px`,
            height: `${originalSizeOfZone.height * ratio}px`,
            position: "relative",
          }}
        >
          {displayedData.map((tableData) => (
            <AdminMapViewTable
              key={tableData.id}
              tableData={tableData}
              orderInTable={orderInTable}
              onClickTable={onClickTable}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

AdminMapView.propTypes = {
  onClickTable: PropTypes.func.isRequired,
  activeTablesId: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedZone: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    tables: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        name: PropTypes.string.isRequired,
        maxSeat: PropTypes.string,
        coordinates: PropTypes.shape({
          x: PropTypes.number,
          y: PropTypes.number,
          width: PropTypes.number,
          height: PropTypes.number,
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default AdminMapView;
