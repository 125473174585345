import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as SuccessIcon } from "assets/icons/reserve/success.svg";
import Modal from "components/modal/Modal";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";

import "./ReservationSuccessModal.scss";

const ReservationSuccessModal = ({ openSlide, mainElementRef, onClose }) => {
  const { t } = useTranslation();

  const ReservationSuccessModalHeader = (
    <div className="ReservationSuccessModalTitle">
      <SuccessIcon />
    </div>
  );

  const ReservationSuccessModalBody = (
    <div className="ReservationSuccessModalBodyTables">
      <h4 className="Medium">{t("reservation.sentSuccessfully")}</h4>
      <h6>{t("reservation.successMessage")}</h6>
    </div>
  );

  const ReservationSuccessModalFooter = (
    <div className="ReservationSuccessModalFooter">
      <PrimaryButton
        onClick={onClose}
        text="Close"
        type={PRIMARY_BUTTON_ENUMS.types.TYPE_B}
      />
    </div>
  );
  return (
    <Modal
      header={ReservationSuccessModalHeader}
      body={ReservationSuccessModalBody}
      footer={ReservationSuccessModalFooter}
      openSlide={openSlide}
      mainElementRef={mainElementRef}
    />
  );
};

export default ReservationSuccessModal;
