import React, { useEffect } from "react";
import { PhoneInput } from "react-international-phone";
import cx from "classnames";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { ENUMS } from "components/elements/email-or-phone/EmailOrPhone";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import CTAButton, {
  ENUMS as ENUMS_CTA_BUTTON,
} from "components/buttons/cta-button/CTAButton";
import { ReactComponent as ArrowIcon } from "assets/icons/arrows/ArrowRight.svg";
import { STORE_NAMES } from "utils/constants/redux";

import "./ReservationContactForm.scss";

const ReservationContactForm = ({ formData, setFormData, handleNextStep }) => {
  const { t } = useTranslation();
  const schemas = useValidationSchema(t);
  const { reservationNameAndPhoneModalSchema } = schemas;
  const guest = useSelector((state) => state[STORE_NAMES.guest]);

  const methods = useForm({
    resolver: zodResolver(reservationNameAndPhoneModalSchema),
    criteriaMode: "all",
    // defaultValues: {
    //   guestName: guest?.name,
    //   guestPhone: guest?.phoneNumber,
    // },
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    setValue,
  } = methods;

  useEffect(() => {
    if (!formData.guestName && guest?.name) {
      setFormData((prev) => {
        return {
          ...prev,
          guestName: guest.name,
        };
      });
      setValue("guestName", guest.name);
    }
    if (!formData.guestPhone && guest?.phoneNumber) {
      setFormData((prev) => {
        return {
          ...prev,
          guestPhone: guest.phoneNumber,
        };
      });
      setValue("guestPhone", guest.phoneNumber);
    }
    if (!formData.guestsCount) {
      setFormData((prev) => {
        return {
          ...prev,
          guestsCount: 1,
        };
      });
    }
  }, []);

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handlePhoneInputChange = (phone, meta, onChange) => {
    if (phone && phone !== ENUMS.plusSign + meta.country.dialCode) {
      setFormData((prev) => {
        return {
          ...prev,
          guestPhone: phone,
        };
      });
      onChange(phone);
    }
  };

  const handleGuestsCount = (num) => {
    setFormData({
      ...formData,
      guestsCount: num,
    });
  };

  return (
    <div className="ReservationContactForm">
      <div className="ReservationSection">
        <h5>{t("reservation.numberOfGuest")}</h5>
        <div className="ReservationNumberOfGuestAndDuration">
          {Array.from({ length: 100 }).map((_, index) => {
            const num = index + 1;
            return (
              <div
                key={num}
                className={cx("ReservationSelectionCount", {
                  isActive: formData.guestsCount === num,
                })}
                onClick={() => handleGuestsCount(num)}
              >
                {num}
              </div>
            );
          })}
        </div>
      </div>
      <form onSubmit={(e) => e.preventDefault()} className="ContactForm">
        <InputControl
          type="text"
          placeholder={t("inputs.reserveeName")}
          required
          name="guestName"
          labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
          func={{
            ...register("guestName", {
              onChange: handleInputChange,
            }),
          }}
          hasError={errors.guestName}
          value={formData.guestName}
          error={
            <ErrorMessage
              errors={errors}
              name="guestName"
              render={({ message }) => (
                <p className="h7 error-message">{message}</p>
              )}
            />
          }
        />
        <Controller
          name="guestPhone"
          control={control}
          value={formData.guestPhone}
          render={({ field: { onChange } }) => (
            <>
              <PhoneInput
                {...register("guestPhone")}
                value={formData?.guestPhone || ""}
                onChange={(phone, meta) => {
                  handlePhoneInputChange(phone, meta, onChange);
                }}
                name="guestPhone"
                placeholder={t("inputs.phoneNumber")}
                defaultCountry={ENUMS.defaultCountry}
                forceDialCode
                hideDropdown
                className={cx("phone-input", {
                  hasError: errors.guestPhone,
                })}
                inputProps={{
                  autoComplete: "off",
                }}
              />

              <div className="EmailOrPhoneSignInMethodError">
                {errors.guestPhone && (
                  <ErrorMessage
                    errors={errors}
                    name="guestPhone"
                    render={({ message }) => (
                      <p className="h7 error-message">{message}</p>
                    )}
                  />
                )}
              </div>
            </>
          )}
        />

        <CTAButton
          onClick={handleSubmit(handleNextStep)}
          icon={<ArrowIcon />}
          name={t("buttons.continue")}
          type={ENUMS_CTA_BUTTON.types.TYPE_R}
          className="ReservationContactFormContinueButton"
        />
      </form>
    </div>
  );
};
ReservationContactForm.propTypes = {
  formData: PropTypes.shape({
    guestName: PropTypes.string.isRequired,
    guestPhone: PropTypes.string.isRequired,
    guestsCount: PropTypes.number,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
};
export default ReservationContactForm;
