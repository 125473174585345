import { AXIOS_NO_AUTH } from "../axios";
import { API_PATH_PARAMS, QUERY_PARAMS } from "../../constants/api";

export const aiTranslate = ({
  businessId,
  type,
  sourceLang,
  targetLangs,
  translateData,
}) =>
  AXIOS_NO_AUTH.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.menus}/${API_PATH_PARAMS.ai}/${API_PATH_PARAMS.translate}?${QUERY_PARAMS.what}=${type}&${QUERY_PARAMS.sourceLang}=${sourceLang}&${QUERY_PARAMS.targetLangs}=${targetLangs}`,
    translateData
  );
