import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Spinner from "components/elements/spinner/Spinner";
import ReservationStepper from "pages/client/reservation/create-reservation/reservation-stepper/ReservationStepper";
import { STORE_NAMES } from "utils/constants/redux";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import useAsync from "utils/hooks/useAsync";
import { getAllBusinesses } from "utils/api/services/business";
import { getZones } from "utils/api/services/zone";
import { RESERVATION_STEP_TYPES } from "pages/client/reservation/create-reservation/ClientReservationHelper";
import EmptyState from "components/admin/empty-state/EmptyState";
import NoReservation from "assets/icons/reserve/NoReservation.svg";
import GuestPageHeader from "components/elements/guest-page-header/GuestPageHeader";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { createDOBucketName } from "utils/DO-Spaces";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";

import "./CreateReservation.scss";

const CreateReservation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const guest = useSelector((state) => state[STORE_NAMES.guest]);
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(
    RESERVATION_STEP_TYPES.businessSelection
  );
  const [formData, setFormData] = useState({
    reservationId: null,
    table: null,
    guestId: guest?.id || null,
    guestName: "",
    guestPhone: "",
    guestComment: "",
    guestsCount: null,
    startDateTime: "",
    endDateTime: "",
    date: "",
    time: "",
    reservationDuration: `1 ${t("common.time.shortVersion.hour")}`,
    business: null,
  });

  const { result: allBusinesses, loading: isBusinessesLoading } = useAsync(
    getAllBusinesses,
    { immediate: true }
  );
  const { result: zonesOfSelectedBusiness } = useAsync(getZones, {
    immediate: !!formData.business?.id,
    params: [formData.business?.id],
  });

  useEffect(() => {
    const businessId = Number(
      searchParams.get(QUERY_PARAMS.reservation.businessId)
    );
    const tableId = Number(searchParams.get(QUERY_PARAMS.reservation.tableId));
    const zoneId = Number(searchParams.get(QUERY_PARAMS.reservation.zoneId));

    if (businessId && allBusinesses) {
      const selectedBusiness = allBusinesses.find(
        (business) => business.id === businessId
      );
      if (
        !selectedBusiness?.reservationVREnabled &&
        !selectedBusiness?.reservationMapEnabled
      ) {
        setActiveStep(RESERVATION_STEP_TYPES.chooseDate);
      } else {
        setActiveStep(RESERVATION_STEP_TYPES.tableSelectionType);
      }
    }

    if (tableId && zoneId) {
      setActiveStep(RESERVATION_STEP_TYPES.chooseDate);
    }
  }, [allBusinesses]);

  const displayedZoneOfSelectedBusiness = useMemo(() => {
    if (!zonesOfSelectedBusiness) return [];

    return zonesOfSelectedBusiness
      .filter((zone) => !zone.isArchived)
      .map((zone) => {
        const editedTables = zone.tables
          .filter((table) => !table.isArchived)
          .map((table) => {
            return {
              ...table,
              zoneName: zone.name,
            };
          });
        return { ...zone, tables: editedTables };
      });
  }, [zonesOfSelectedBusiness]);

  useEffect(() => {
    const selectedBusinessId = Number(
      searchParams.get(QUERY_PARAMS.reservation.businessId)
    );
    const selectedTableId = Number(
      searchParams.get(QUERY_PARAMS.reservation.tableId)
    );

    if (selectedBusinessId && allBusinesses?.length > 0) {
      const selectedBusiness = allBusinesses.find(
        (business) => business.id === selectedBusinessId
      );
      if (selectedBusiness) {
        setFormData((prev) => ({ ...prev, business: selectedBusiness }));
      }
    }

    if (selectedTableId && displayedZoneOfSelectedBusiness.length > 0) {
      const selectedTable = displayedZoneOfSelectedBusiness
        .flatMap((zone) => zone.tables)
        .find((table) => table.id === selectedTableId);
      if (selectedTable) {
        setFormData((prev) => ({ ...prev, table: selectedTable }));
      }
    }
  }, [searchParams, allBusinesses, displayedZoneOfSelectedBusiness]);
  const handleGoBack = () => {
    navigate(`${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`);
  };

  if (formData.business && !formData.business.reservation) {
    return (
      <div className="NotOpenForReservation">
        <GuestPageHeader
          pageTitle={t("reservation.reservation")}
          showBurgerMenu={false}
          onGoBack={handleGoBack}
        />
        <div className="ReservationStepperPreviewBusiness">
          <div className="ReservationStepperPreviewBusinessImage">
            <ImageWithPlaceholder
              imageSource={createDOBucketName(formData.business?.images.logo)}
              placeholder={IMAGE_ITEM_PLACEHOLDER}
              alt="logo"
            />
          </div>
          <h5 className="Medium">{formData.business?.name}</h5>
        </div>
        <EmptyState
          icon={NoReservation}
          description={t("emptyStates.notOpenForReservations")}
          isAdmin={false}
          title={t("emptyStates.noReservations")}
        />
      </div>
    );
  }

  if (isBusinessesLoading) {
    return <Spinner />;
  }

  return (
    <div className="ClientReservation">
      <ReservationStepper
        className="ReservationStepper"
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        formData={formData}
        setFormData={setFormData}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        allBusinesses={allBusinesses}
        zones={displayedZoneOfSelectedBusiness}
      />
    </div>
  );
};

export default CreateReservation;
