import { createSlice } from "@reduxjs/toolkit";

import { STORE_NAMES } from "utils/constants/redux";
import {
  getBusinessAsync,
  updateBusinessAsync,
  updateBusinessSettingsAsync,
} from "redux/actions/businessAction";
import {
  getAdminMetaDataAsync,
  getGuestMetaDataAsync,
} from "redux/actions/metaDataAction";
import { getUsers } from "redux/actions/userAction";

const initialState = {
  thunkAPIStates: {
    getBusiness: false,
    updateBusiness: false,
    updateBusinessSettings: false,
    getGuestMetaData: false,
    getAdminMetaData: false,
    getBusinessUsers: false,
  },
  business: null,
  error: null,
  users: null,
};

export const businessStore = createSlice({
  name: STORE_NAMES.business,
  initialState: initialState,
  reducers: {
    resetBusinessStore: () => {
      return { ...initialState };
    },
    setBusiness: (state, action) => {
      const business = action.payload;
      return { ...state, business: business };
    },
    createBusinessUsers: (state, { payload }) => {
      const existingUserIndex = state.users.findIndex(
        (user) => user.id === payload.id
      );

      if (existingUserIndex !== -1) {
        state.users[existingUserIndex] = payload;
      } else {
        state.users.push(payload);
      }
    },
    updateBusinessUsers: (state, { payload }) => {
      state.users = state.users.map((user) => {
        if (user.id === payload.id) {
          return { ...user, ...payload };
        }
        return user;
      });
    },
    setOrReplaceBusiness: (state, { payload }) => {
      return {
        ...state,
        business: {
          ...state.business,
          isArchived: payload?.isArchived,
        },
      };
    },
    deleteBusinessUsers: (state, { payload }) => {
      state.users = state.users.filter((user) => user.id !== payload.id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBusinessAsync.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, getBusiness: true },
      };
    });
    builder.addCase(getBusinessAsync.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, getBusiness: false },
      };
    });
    builder.addCase(getBusinessAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        business: payload,
        thunkAPIStates: { ...state.thunkAPIStates, getBusiness: false },
      };
    });
    builder.addCase(updateBusinessAsync.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, updateBusiness: true },
      };
    });
    builder.addCase(updateBusinessAsync.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, updateBusiness: false },
      };
    });
    builder.addCase(updateBusinessAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        business: payload,
        thunkAPIStates: { ...state.thunkAPIStates, updateBusiness: false },
      };
    });
    builder.addCase(updateBusinessSettingsAsync.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: {
          ...state.thunkAPIStates,
          updateBusinessSettings: true,
        },
      };
    });
    builder.addCase(
      updateBusinessSettingsAsync.rejected,
      (state, { payload }) => {
        return {
          ...state,
          error: payload,
          thunkAPIStates: {
            ...state.thunkAPIStates,
            updateBusinessSettings: false,
          },
        };
      }
    );
    builder.addCase(
      updateBusinessSettingsAsync.fulfilled,
      (state, { payload }) => {
        return {
          ...state,
          business: { ...state.business, ...payload },
          thunkAPIStates: {
            ...state.thunkAPIStates,
            updateBusinessSettings: false,
          },
        };
      }
    );
    builder.addCase(getGuestMetaDataAsync.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, getGuestMetaData: true },
      };
    }),
      builder.addCase(getGuestMetaDataAsync.rejected, (state, { payload }) => {
        return {
          ...state,
          error: payload,
          thunkAPIStates: { ...state.thunkAPIStates, getGuestMetaData: false },
        };
      });
    builder.addCase(getGuestMetaDataAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        business: payload.business,
        thunkAPIStates: { ...state.thunkAPIStates, getGuestMetaData: false },
      };
    });
    builder.addCase(getAdminMetaDataAsync.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, getAdminMetaData: true },
      };
    });
    builder.addCase(getAdminMetaDataAsync.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, getAdminMetaData: false },
      };
    });
    builder.addCase(getAdminMetaDataAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        users: payload.users,
        business: payload.business,
        thunkAPIStates: { ...state.thunkAPIStates, getAdminMetaData: false },
      };
    });
    builder.addCase(getUsers.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, getBusinessUsers: true },
      };
    });
    builder.addCase(getUsers.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, getBusinessUsers: false },
      };
    });
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      return {
        ...state,
        users: payload.data,
        thunkAPIStates: { ...state.thunkAPIStates, getBusinessUsers: false },
      };
    });
  },
});

export const {
  resetBusinessStore,
  setBusiness,
  createBusinessUsers,
  updateBusinessUsers,
  deleteBusinessUsers,
  setOrReplaceBusiness,
} = businessStore.actions;
export default businessStore.reducer;
