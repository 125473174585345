import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { useSelector } from "react-redux";

import { STORE_NAMES } from "utils/constants/redux";
import BusinessHeroSection from "./business-hero-section/BusinessHeroSection";
import CTAButton, { ENUMS } from "components/buttons/cta-button/CTAButton";
import { ReactComponent as IconMenu } from "assets/icons/menu/restaurant-menu.svg";
import { ReactComponent as IconLocation } from "assets/icons/address/location.svg";
import { ROUTE_NAME } from "utils/constants/routes";
import WorkingHoursInfo, {
  ENUMS as WORKING_HOURS_ENUMS,
} from "components/elements/working-hours-info/WorkingHoursInfo";
import { ReactComponent as IconPhone } from "assets/icons/sosial-media/Phone.svg";
import { ReactComponent as IconInstagram } from "assets/icons/sosial-media/Instagram.svg";
import { ReactComponent as IconGmail } from "assets/icons/sosial-media/Mail.svg";
import { ReactComponent as IconWhatsapp } from "assets/icons/sosial-media/Whatsapp.svg";
import { ReactComponent as IconAddress } from "assets/icons/address/address.svg";
import { createDOBucketName } from "utils/DO-Spaces";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import LogoSpinner from "components/elements/logo-spinner/LogoSpinner";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import useMouseDragHorizontal from "utils/hooks/useMouseDragHorizontal";

import "./Business.scss";

const Business = () => {
  const {
    sliderRef: sliderRefOtherPhotos,
    handleMouseDownHandler: handleMouseDownHandlerOtherPhotos,
  } = useMouseDragHorizontal();
  const {
    sliderRef: sliderRefWorkingHours,
    handleMouseDownHandler: handleMouseDownHandlerWorkingHours,
  } = useMouseDragHorizontal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { business, isLoading } = useSelector(
    (state) => state[STORE_NAMES.business]
  );

  const handleGoToMenu = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.menu}`
    );
  };

  const areWorkingHoursEqual = (workingHours, days) => {
    const firstDay = days[0];
    return days.every((day) => {
      return (
        workingHours[day].from === workingHours[firstDay].from &&
        workingHours[day].to === workingHours[firstDay].to &&
        workingHours[day].isWorking
      );
    });
  };

  const isSameForEntireWeek = (workingHours) => {
    const allDays = Object.keys(workingHours);
    return areWorkingHoursEqual(workingHours, allDays);
  };

  const isSameForEntireWeekDays = (workingHours) => {
    const weekdays = Object.keys(workingHours).slice(0, 5);
    return areWorkingHoursEqual(workingHours, weekdays);
  };
  const isSameForWeekend = (workingHours) => {
    const weekendDays = Object.keys(workingHours).slice(5);
    return areWorkingHoursEqual(workingHours, weekendDays);
  };

  const businessWorkingHours = Object.keys(business.workingHours);

  // let restOfDays;
  //
  // switch (true) {
  //   case isSameForEntireWeek(business.workingHours):
  //   case isSameForWeekend(business.workingHours) &&
  //     isSameForEntireWeekDays(business.workingHours):
  //     restOfDays = [];
  //     break;
  //
  //   case isSameForWeekend(business.workingHours):
  //     restOfDays = Object.keys(business.workingHours).slice(0, 5);
  //     break;
  //
  //   case isSameForEntireWeekDays(business.workingHours):
  //     restOfDays = Object.keys(business.workingHours).slice(5);
  //     break;
  //
  //   default:
  //     restOfDays = Object.keys(business.workingHours);
  //     break;
  // }

  const editedAddressLine = [
    business.address.street,
    business.address.city,
    business.address.country,
    business.address.home,
  ]
    .filter((item) => item !== undefined && item !== "" && item !== null)
    .join(", ");
  const anySocialAddress =
    business?.contact?.phone.value ||
    business?.contact?.instagram.value ||
    business?.contact?.gmail.value ||
    business?.contact?.whatsapp.value;

  const anyAddressInfo = business?.address?.city || business?.address.street;

  if (isLoading) {
    return <LogoSpinner />;
  }

  const businessIsWorking = !Object.values(business.workingHours).every(
    (day) => !day.isWorking
  );

  return (
    <div className="Business">
      <BusinessHeroSection
        backgroundImage={createDOBucketName(business.images.background)}
        name={business.name}
      />
      <div className="BusinessBodyContainer">
        <div className="BusinessHeader">
          <div className="BusinessLogoImageWrapper">
            <ImageWithPlaceholder
              imageSource={createDOBucketName(business.images.logo)}
              placeholder={IMAGE_ITEM_PLACEHOLDER}
              alt="logo"
            />
          </div>
          <div className="BusinessServiceFeeInfo h7 SemiBold">
            {t("inputs.serviceFee")}: <span>{business.serviceFee}%</span>
          </div>
          <h2 className="SemiBold BusinessProfileName"> {business.name}</h2>
          {anyAddressInfo && (
            <div className="BusinessHeaderAddress">
              <div>
                <IconLocation />
              </div>
              {/*<h6>{`${business.address.city} ${*/}
              {/*  business.address.city && t("inputs.city").toLowerCase()*/}
              {/*} ${business.address.city && business.address.street && ","} ${*/}
              {/*  business.address.street*/}
              {/*} `}</h6>*/}
              <h6>
                {`${business.address.city || ""} ${
                  business.address.city ? t("inputs.city").toLowerCase() : ""
                } ${
                  business.address.city && business.address.street ? "," : ""
                } ${business.address.street || ""}`}
              </h6>
            </div>
          )}
        </div>
        <div className="BusinessMenuButtonWrapper">
          <CTAButton
            onClick={handleGoToMenu}
            name={t("navbarRoutes.menu")}
            type={ENUMS.types.TYPE_L}
            icon={<IconMenu />}
            className={"BusinessMenuButton"}
          />
        </div>

        {businessIsWorking && (
          <div
            className={cx("BusinessWorkingHoursInfo", {
              isDifferent: !isSameForEntireWeek(business.workingHours),
            })}
          >
            {isSameForEntireWeek(business.workingHours) ? (
              <WorkingHoursInfo
                name={t("common.days.everyday")}
                from={business.workingHours.monday.from}
                to={business.workingHours.monday.to}
                type={WORKING_HOURS_ENUMS.types.TYPE_A}
              />
            ) : (
              <>
                <h6 className="BusinessWorkingHoursTitle SemiBold">
                  {t("business.workingHours")}
                </h6>
                <div
                  className="WorkingHoursInfoWrapper"
                  ref={sliderRefWorkingHours}
                  onMouseDown={handleMouseDownHandlerWorkingHours}
                >
                  {/*{isSameForEntireWeekDays(business.workingHours) && (*/}
                  {/*  <WorkingHoursInfo*/}
                  {/*    name={t("common.days.everyWeekday")}*/}
                  {/*    from={business.workingHours.monday.from}*/}
                  {/*    to={business.workingHours.monday.to}*/}
                  {/*    type={WORKING_HOURS_ENUMS.types.TYPE_B}*/}
                  {/*  />*/}
                  {/*)}*/}
                  {/*{isSameForWeekend(business.workingHours) && (*/}
                  {/*  <WorkingHoursInfo*/}
                  {/*    name={t("common.days.weekend")}*/}
                  {/*    from={business.workingHours.sunday.from}*/}
                  {/*    to={business.workingHours.sunday.to}*/}
                  {/*    type={WORKING_HOURS_ENUMS.types.TYPE_B}*/}
                  {/*  />*/}
                  {/*)}*/}
                  {businessWorkingHours
                    .filter((day) => business.workingHours[day].isWorking)
                    .map((day, index) => (
                      <WorkingHoursInfo
                        name={t(`weekdays.${day}`)}
                        from={business.workingHours[day].from}
                        to={business.workingHours[day].to}
                        type={WORKING_HOURS_ENUMS.types.TYPE_B}
                        key={index}
                      />
                    ))}
                </div>
              </>
            )}
          </div>
        )}

        {anySocialAddress && (
          <div className="BusinessContacts">
            <h6 className="SemiBold">{t("business.contactWith")}</h6>
            <div className="BusinessContactsButtonsContainer">
              {business.contact.phone.value && (
                <CTAButton
                  onClick={() =>
                    window.open(`tel:${business.contact.phone.value}`, "_blank")
                  }
                  name={t("inputs.phoneNumber")}
                  type={ENUMS.types.TYPE_F}
                  icon={<IconPhone />}
                  className="BusinessContactButton"
                />
              )}

              {business.contact.instagram.value && (
                <CTAButton
                  onClick={() =>
                    window.open(business.contact.instagram.value, "_blank")
                  }
                  name={t("socials.instagram")}
                  type={ENUMS.types.TYPE_F}
                  icon={<IconInstagram />}
                  className="BusinessContactButton"
                />
              )}

              {business.contact.gmail.value && (
                <CTAButton
                  onClick={() =>
                    window.open(
                      `mailto:${business.contact.gmail.value}`,
                      "_blank"
                    )
                  }
                  name={t("socials.email")}
                  type={ENUMS.types.TYPE_F}
                  icon={<IconGmail />}
                  className="BusinessContactButton"
                />
              )}

              {business.contact.whatsapp.value && (
                <CTAButton
                  onClick={() =>
                    window.open(
                      `https://wa.me/${business.contact.whatsapp.value}`,
                      "_blank"
                    )
                  }
                  name={t("socials.whatsapp")}
                  type={ENUMS.types.TYPE_F}
                  icon={<IconWhatsapp />}
                  className="BusinessContactButton"
                />
              )}
            </div>
          </div>
        )}

        {business.address.googleLink && (
          <>
            <div className="BusinessAddress">
              <CTAButton
                onClick={() =>
                  window.open(business.address.googleLink, "_blank")
                }
                name={editedAddressLine}
                type={ENUMS.types.TYPE_G}
                icon={<IconAddress />}
              />
            </div>
            {/*<div className="BusinessLocationMap">*/}
            {/*  <a*/}
            {/*    href={business.address.googleLink}*/}
            {/*    target="_blank"*/}
            {/*    rel="noreferrer"*/}
            {/*  >*/}
            {/*    <img src={IMAGE_MAP} alt="map" />*/}
            {/*  </a>*/}
            {/*</div>*/}
          </>
        )}

        {business.description && (
          <div className="BusinessDescription">
            <h6 className="SemiBold">{t("common.about")}</h6>
            <p className="h7 Medium">{business.description}</p>
          </div>
        )}

        {business.images &&
          business.images.other &&
          business.images.other.length > 0 && (
            <div
              className="BusinessProfileOtherImagesContainer"
              ref={sliderRefOtherPhotos}
              onMouseDown={handleMouseDownHandlerOtherPhotos}
            >
              {business.images.other.map((image, index) => (
                <div className="BusinessProfileOtherImage" key={index}>
                  <ImageWithPlaceholder
                    imageSource={createDOBucketName(image)}
                    placeholder={IMAGE_ITEM_PLACEHOLDER}
                    alt="other"
                  />
                </div>
              ))}
            </div>
          )}
      </div>
    </div>
  );
};

export default Business;
