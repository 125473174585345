import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import ReservationStepperHeader from "components/elements/guest-page-header/GuestPageHeader";
import { ROUTE_NAME } from "utils/constants/routes";
import { RESERVATION_STEP_TYPES } from "pages/client/reservation/create-reservation/ClientReservationHelper";
import ReservationStepperPreview from "pages/client/reservation/create-reservation/reservation-stepper/reservation-stepper-preview/ReservationStepperPreview";
import { STORE_NAMES } from "utils/constants/redux";
import ReservationBusinessSelection from "pages/client/reservation/create-reservation/business-selection-form/ReservationBusinessSelection";
import ReservationTableSelectionType from "pages/client/reservation/create-reservation/reservation-table-selection-type/ReservationTableSelectionType";
import ReservationDateForm from "pages/client/reservation/create-reservation/reservation-date-form/ReservationDateForm";
import ReservationContactForm from "pages/client/reservation/create-reservation/reservation-contact-form/ReservationContactForm";
import ReservationFinalInfo from "pages/client/reservation/create-reservation/reservation-final-info/ReservationFinalInfo";

import "./ReservationStepper.scss";

const ReservationStepperProgressBar = ({ activeStep, steps }) => {
  const { t } = useTranslation();
  const numberOfSteps = Object.keys(steps).length;
  const activeStepperIndex = Object.keys(steps).indexOf(activeStep.key);

  return (
    <>
      <div>
        <h3 className="SemiBold">{t(activeStep.title)}</h3>
      </div>
      <div className="StepperProgressBar">
        {Object.keys(steps).map((stepKey, index) => {
          const stepWidth = `calc((100% - ${
            (numberOfSteps - 1) * 16
          }px) / ${numberOfSteps})`;
          return (
            <div
              key={stepKey}
              className={cx("StepperProgressBarLine", {
                isPassed: index <= activeStepperIndex,
              })}
              style={{ width: stepWidth }}
            ></div>
          );
        })}
      </div>
    </>
  );
};

ReservationStepperProgressBar.propTypes = {
  activeStep: PropTypes.shape({
    title: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  steps: PropTypes.objectOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const ReservationStepper = ({
  className,
  activeStep,
  setActiveStep,
  formData,
  setFormData,
  searchParams,
  setSearchParams,
  allBusinesses,
  zones,
}) => {
  const [steps, setSteps] = useState(RESERVATION_STEP_TYPES);

  useEffect(() => {
    if (formData.business) {
      if (
        !formData.business.reservationVREnabled &&
        !formData.business.reservationMapEnabled
      ) {
        const editedSteps = { ...RESERVATION_STEP_TYPES };
        delete editedSteps.tableSelectionType;
        setSteps(editedSteps);
      } else {
        setSteps(RESERVATION_STEP_TYPES);
      }
    }
  }, [formData.business]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const guest = useSelector((state) => state[STORE_NAMES.guest]);

  const handleGoBack = () => {
    if (activeStep.type === steps.businessSelection.type) {
      const route = guest.id
        ? `${ROUTE_NAME.client}${ROUTE_NAME.reservation}${ROUTE_NAME.myReservations}`
        : `${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`;
      navigate(route);
    } else {
      const arrayOfSteps = Object.keys(steps);
      const indexOfActiveStep = arrayOfSteps.indexOf(activeStep.key);
      const navigatedStep = arrayOfSteps[indexOfActiveStep - 1];
      setActiveStep(steps[navigatedStep]);
    }
  };

  const handleNextStep = () => {
    const arrayOfSteps = Object.keys(steps);
    const indexOfActiveStep = arrayOfSteps.indexOf(activeStep.key);
    const navigatedStep = arrayOfSteps[indexOfActiveStep + 1];
    setActiveStep(steps[navigatedStep]);
  };

  const renderStepContent = () => {
    const stepComponents = {
      [steps.businessSelection.type]: (
        <ReservationBusinessSelection
          allBusinesses={allBusinesses}
          handleNextStep={handleNextStep}
        />
      ),
      [steps?.tableSelectionType?.type]: (
        <ReservationTableSelectionType
          formData={formData}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          zones={zones}
          handleNextStep={handleNextStep}
          activeStep={activeStep}
        />
      ),
      [steps.chooseDate.type]: (
        <ReservationDateForm
          formData={formData}
          setActiveStep={setActiveStep}
          setFormData={setFormData}
          handleNextStep={handleNextStep}
        />
      ),
      [steps.addContact.type]: (
        <ReservationContactForm
          formData={formData}
          setActiveStep={setActiveStep}
          setFormData={setFormData}
          handleNextStep={handleNextStep}
        />
      ),
      [steps.reservationFinalDetail.type]: (
        <ReservationFinalInfo formData={formData} setFormData={setFormData} />
      ),
    };

    return stepComponents[activeStep.type] || null;
  };

  return (
    <div className={className}>
      <ReservationStepperHeader
        pageTitle={t("reservation.reservation")}
        onGoBack={handleGoBack}
        showBurgerMenu={false}
      />
      <ReservationStepperProgressBar steps={steps} activeStep={activeStep} />
      {activeStep.type !== steps.businessSelection.type && (
        <ReservationStepperPreview
          formData={formData}
          activeStep={activeStep}
          setFormData={setFormData}
        />
      )}
      {renderStepContent()}
    </div>
  );
};

ReservationStepper.propTypes = {
  className: PropTypes.string,
  activeStep: PropTypes.shape({
    title: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    type: PropTypes.string,
  }).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  formData: PropTypes.object,
  setFormData: PropTypes.func.isRequired,
  searchParams: PropTypes.object,
  setSearchParams: PropTypes.func.isRequired,
  allBusinesses: PropTypes.array,
};

export default ReservationStepper;
