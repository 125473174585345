import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import isEqual from "lodash/isEqual";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { useSearchParams } from "react-router-dom";
import cx from "classnames";

import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import PageTitle from "components/elements/page-title/PageTitle";
import AddPhoto from "components/admin/cards/add-photo/AddPhoto";
import WorkingHours from "components/admin/cards/working-hours/WorkingHours";
import Contact from "components/admin/elements/contact/Contact";
import { COUNTRIES } from "utils/constants/data/sample-menu";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import { STORE_NAMES } from "utils/constants/redux";
import {
  getBusinessAsync,
  updateBusinessAsync,
} from "redux/actions/businessAction";
import { handleOnAsyncError, handleOnAsyncSuccess } from "utils/helpers";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
// import AddNewButton, {
//   ENUMS as ENUMS_ADD_NEW_BUTTON,
// } from "components/admin/buttons/add-new-button/AddNewButton";
// import AddItemModal from "components/admin/cards/add-item-modal/AddItemModal";
// import useOutsideClick from "utils/hooks/useOutsideClick";
// import Tag, { ENUMS as ENUMS_TAG } from "components/elements/tag/Tag";
import Spinner from "components/elements/spinner/Spinner";
import usePageEvents from "utils/hooks/useMixPanelEvents";
import {
  createDOBucketName,
  removeImageFromDO,
  uploadImageToDO,
} from "utils/DO-Spaces";
import { DO_FILE_TYPES, IMAGE_FILE } from "utils/constants/DOSpaces";
import { QUERY_PARAMS } from "utils/constants/routes";
import { ReactComponent as GoogleMapIcon } from "assets/icons/location/GoogleMap.svg";
import { ENUMS } from "components/admin/cards/add-photo/AddPhoto";

import "./AdminBusiness.scss";

const IMAGES_NAMES = {
  logo: "logo",
  background: "background",
};

const AdminBusiness = () => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const { saveEvent } = usePageEvents();
  const dispatch = useDispatch();
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const isLoadingGetBusiness = useSelector(
    (state) => state[STORE_NAMES.business].thunkAPIStates?.getBusiness
  );
  const isLoadingUpdateBusiness = useSelector(
    (state) => state[STORE_NAMES.business].thunkAPIStates?.updateBusiness
  );

  const [uploadedImages, setUploadedImages] = useState([]);
  const isUnmounting = useRef(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    address: {
      googleLink: "",
      country: COUNTRIES[0],
      city: "",
      street: "",
      zip: "",
    },
    serviceFee: "",
    images: {
      logo: null,
      background: null,
      other: [],
    },
    contact: {
      instagram: "",
      phone: "",
      whatsapp: "",
      gmail: "",
    },
    workingHours: {},
    printers: [],
  });

  const schemas = useValidationSchema(t);
  const businessSchema = schemas.businessSchema(formData.name);

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(businessSchema),
    criteriaMode: "all",
  });

  const businessId = useSelector(
    (state) => state[STORE_NAMES.user].user?.business.id
  );
  const [hasUnSavedChanges, setHasUnSavedChanges] = useState(false);

  const defaultWorkingHours = {
    isWorking: false,
    from: "09:00",
    to: "00:00",
  };

  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const workingHours = Object.fromEntries(
    daysOfWeek.map((day) => [day, { ...defaultWorkingHours }])
  );
  // const [openSlidePrinter, setOpenSlidePrinter, mainElementRefPrinter] =
  //   useOutsideClick();
  // const [selectedPrinter, setSelectedPrinter] = useState(null);
  const getBusinessObject = (business) => ({
    name: business.name,
    description: business.description,
    address: {
      ...business.address,
      country: COUNTRIES.find(
        (country) => country.name === business.address.country
      ),
    },
    serviceFee: String(business.serviceFee) || "",
    images: {
      logo: business.images.logo,
      background: business.images.background,
      other: business.images.other ? [...business.images.other] : [],
    },
    contact: {
      ...business.contact,
      phone: {
        value: business.contact.phone.value
          ? `+${business.contact.phone.value}`
          : "",
      },
      whatsapp: {
        value: business.contact.whatsapp.value
          ? `+${business.contact.whatsapp.value}`
          : "",
      },
    },
    workingHours: {
      ...workingHours,
      ...business.workingHours,
    },
    printers: business.printers ? [...business.printers] : [],
  });

  useEffect(() => {
    dispatch(getBusinessAsync(businessId));

    return () => {
      isUnmounting.current = true;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (isUnmounting.current) {
        uploadedImages.forEach((image) => {
          if (!getBusinessObject(business)?.images?.other.includes(image)) {
            removeImageFromDO(image);
          }
        });
      }
    };
  }, [business, uploadedImages]);

  useEffect(() => {
    if (business) {
      setFormData(getBusinessObject(business));
    }
  }, [business]);

  useEffect(() => {
    const unSavedChanges = !isEqual(formData, getBusinessObject(business));
    setHasUnSavedChanges(unSavedChanges);
    if (unSavedChanges) {
      setSearchParams({
        ...searchParams,
        [QUERY_PARAMS.unsavedChanges]: true,
      });
    } else {
      searchParams.delete(QUERY_PARAMS.unsavedChanges);
      setSearchParams(searchParams);
    }
  }, [formData]);
  const handleOnSelectSingleImage = async (selectedImage, name) => {
    const imageType =
      name === IMAGES_NAMES.logo
        ? DO_FILE_TYPES.BUSINESS_LOGO_IMAGE
        : DO_FILE_TYPES.BUSINESS_BACKGROUND_IMAGE;
    try {
      await uploadImageToDO({
        image: selectedImage,
        fileType: imageType,
        maxSizeMB:
          imageType === DO_FILE_TYPES.BUSINESS_LOGO_IMAGE
            ? IMAGE_FILE.businessLogo.maxSizeMB
            : IMAGE_FILE.businessBackground.maxSizeMB,
        maxWidthOrHeight:
          imageType === DO_FILE_TYPES.BUSINESS_LOGO_IMAGE
            ? IMAGE_FILE.businessLogo.maxWidthOrHeight
            : IMAGE_FILE.businessBackground.maxWidthOrHeight,
        businessId,
        onSuccess: (location) => {
          setFormData((prevData) => ({
            ...prevData,
            images: {
              ...prevData.images,
              [name]: location,
            },
          }));
          setUploadedImages([...uploadedImages, location]);
        },
        onError: () => undefined,
      });
    } catch (error) {
      throw new Error("Something went wrong...", error);
    }
  };

  const handleOnSelectOtherPhotos = async (selectedPhotos) => {
    const selectedPhotosArray = Array.from(selectedPhotos);
    try {
      const uploadedImageLocations = await Promise.all(
        selectedPhotosArray.map((photo) => {
          return new Promise((resolve, reject) => {
            uploadImageToDO({
              image: photo,
              fileType: DO_FILE_TYPES.BUSINESS_OTHER_IMAGES,
              maxSizeMB: IMAGE_FILE.businessBackground.maxSizeMB,
              maxWidthOrHeight: IMAGE_FILE.businessBackground.maxWidthOrHeight,
              businessId,
              onSuccess: (location) => {
                resolve(location);
              },
            }).catch((error) => {
              reject(error);
            });
          });
        })
      );

      setUploadedImages([...uploadedImages, ...uploadedImageLocations]);
      setFormData((prevData) => ({
        ...prevData,
        images: {
          ...prevData.images,
          other: [
            ...uploadedImageLocations.map((location) => location),
            ...formData.images.other,
          ],
        },
      }));
    } catch (error) {
      throw new Error("Something went wrong...", error);
    }
  };

  const handleOnRemoveSingleImage = (name) => {
    setFormData((prevData) => ({
      ...prevData,
      images: {
        ...prevData.images,
        [name]: null,
      },
    }));
  };

  const handleOnRemoveOtherImage = (activeImage) => {
    const updatedImages = formData.images.other.filter(
      (image) => createDOBucketName(image) !== activeImage
    );

    setFormData((prevData) => ({
      ...prevData,
      images: {
        ...prevData.images,
        other: updatedImages,
      },
    }));
  };

  const handleOnEditContact = (e, contact) => {
    setFormData((prevData) => ({
      ...prevData,
      contact: {
        ...prevData.contact,
        [contact]: {
          value: e.target.value,
        },
      },
    }));
  };

  const handleOnChangeTime = (day, value, timeType) => {
    setFormData((prevData) => ({
      ...prevData,
      workingHours: {
        ...prevData.workingHours,
        [day]: {
          ...prevData.workingHours[day],
          [timeType]: value.name,
        },
      },
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Split the name by "." to access nested properties
    const nameParts = name.split(".");
    if (nameParts.length === 2) {
      const [objectName, propertyName] = nameParts;

      // Update the corresponding property inside the object
      setFormData((prevData) => ({
        ...prevData,
        [objectName]: {
          ...prevData[objectName],
          [propertyName]: value,
        },
      }));
    } else {
      // If the name doesn't contain a ".", update the top-level property
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCountryChange = (country) => {
    setFormData((prevData) => ({
      ...prevData,
      address: {
        ...prevData.address,
        country,
      },
    }));
  };

  const handleOnSwitchIsWorking = (day) => {
    setFormData((prevData) => ({
      ...prevData,
      workingHours: {
        ...prevData.workingHours,
        [day]: {
          ...prevData.workingHours[day],
          isWorking: !prevData.workingHours[day].isWorking,
        },
      },
    }));
  };

  const handleOnSave = async () => {
    saveEvent("business");
    //remove redundant uploaded images from AWS
    uploadedImages.map((image) => {
      if (
        image !== formData.images.logo &&
        image !== formData.images.background &&
        !formData.images.other.includes(image)
      ) {
        removeImageFromDO(image);
      }
    });
    setUploadedImages([]);

    // Remove old logo, background, and other images from AWS
    if (business.images.logo !== formData.images.logo) {
      removeImageFromDO(business.images.logo);
    }
    if (business.images.background !== formData.images.background) {
      removeImageFromDO(business.images.background);
    }
    business.images?.other?.forEach((oldImage) => {
      if (!formData.images.other.includes(oldImage)) {
        removeImageFromDO(oldImage);
      }
    });

    const updatedPrinters = formData.printers.map((printer) => {
      const existingPrinter = business?.printers?.find(
        (p) => p.id === printer.id
      );
      if (existingPrinter) {
        return printer;
      }
      return { name: printer.name };
    });

    const updatedFormData = {
      ...formData,
      address: { ...formData.address, country: formData.address.country?.name },
      printers: updatedPrinters,
      serviceFee: Number(formData.serviceFee),
      contact: {
        ...formData.contact,
        phone: { value: formData.contact.phone.value.replace(/\+/g, "") },
        whatsapp: { value: formData.contact.whatsapp.value.replace(/\+/g, "") },
      },
      ...(formData?.workingHours && {
        workingHours: JSON.stringify(formData?.workingHours),
      }),
    };
    const response = await dispatch(
      updateBusinessAsync({ business: updatedFormData, id: businessId })
    );
    if (response.error) {
      handleOnAsyncError(t(commonAsyncErrorMessage));
    } else {
      handleOnAsyncSuccess(t("toastMessages.success.updateBusinessProfile"));
      setFormData(getBusinessObject(response.payload));
    }
  };

  // const handleAddNewPrint = () => {
  //   setOpenSlidePrinter(true);
  //   setSelectedPrinter(null);
  // };
  //
  // const handleOnSavePrinter = async (data, id) => {
  //   const newPrinter = { name: data.name };
  //   if (id) {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       printers: prevData.printers.map((printer) =>
  //         printer.id === id ? newPrinter : printer
  //       ),
  //     }));
  //   } else {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       printers: [
  //         ...prevData.printers,
  //         { ...newPrinter, id: new Date().getTime() },
  //       ],
  //     }));
  //   }
  //   setOpenSlidePrinter(false);
  // };
  //
  // const handleOnDeletePrinter = (id) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     printers: prevData.printers.filter((printer) => printer.id !== id),
  //   }));
  // };
  //
  // const handleOnEditPrinter = (option) => {
  //   setSelectedPrinter(option);
  //   setOpenSlidePrinter(true);
  // };

  if (isLoadingGetBusiness) {
    return <Spinner />;
  }

  return (
    <div className="AdminBusiness">
      <PageTitle
        title={t("navbarRoutes.pageTitles.business")}
        body={<h6 className="Medium">id: {businessId}</h6>}
      >
        {hasUnSavedChanges && (
          <PrimaryButton
            onClick={handleSubmit(handleOnSave)}
            text={t("buttons.save")}
            isLoading={isLoadingUpdateBusiness}
            className="AdminBusinessCaptionSaveButton"
          />
        )}
      </PageTitle>
      <div className="AdminBusinessInfo">
        <form className="AdminBusinessInfoAboutForm">
          <div className="AdminBusinessInfoAboutFormPart">
            <div className="AdminBusinessInfoAboutFormGroup">
              <h2 className="AdminBusinessInfoAboutFormGroupTitle SemiBold">
                {t("common.general")}
              </h2>
              <div className="InputGroups">
                <InputControl
                  labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                  placeholder={t("inputs.businessName")}
                  value={formData?.name}
                  name="name"
                  maxLength={100}
                  required
                  error={
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      render={({ message }) => (
                        <p className="h7 error-message">{message}</p>
                      )}
                    />
                  }
                  hasError={errors.name}
                  func={{
                    ...register("name", {
                      onChange: handleInputChange,
                    }),
                  }}
                />
                <InputControl
                  labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                  placeholder={t("inputs.description")}
                  textarea
                  value={formData?.description}
                  name="description"
                  error={
                    <ErrorMessage
                      errors={errors}
                      name="description"
                      render={({ message }) => (
                        <p className="h7 error-message">{message}</p>
                      )}
                    />
                  }
                  hasError={errors.description}
                  func={{
                    ...register("description", {
                      onChange: handleInputChange,
                    }),
                  }}
                />
                <InputControl
                  labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                  placeholder={t("inputs.serviceFee")}
                  value={formData?.serviceFee}
                  name="serviceFee"
                  definitionText="%"
                  type="number"
                  hasError={errors.serviceFee}
                  func={{
                    ...register("serviceFee", {
                      setValueAs: (value) =>
                        value === "" ? undefined : parseFloat(value),
                      onChange: handleInputChange,
                    }),
                  }}
                  error={
                    <ErrorMessage
                      errors={errors}
                      name="serviceFee"
                      render={({ message }) => (
                        <p className="h7 error-message">{message}</p>
                      )}
                    />
                  }
                />
              </div>
            </div>
            <div className="AdminBusinessInfoAboutFormGroup">
              <h2 className="AdminBusinessInfoAboutFormGroupTitle SemiBold">
                {t("common.address")}
              </h2>
              <div className="InputGroups">
                <Dropdown
                  onChange={handleCountryChange}
                  placeholder={t("business.country")}
                  options={COUNTRIES}
                  value={formData?.address.country}
                  name="address.country"
                />
                <div className="InputGroupsMini">
                  <InputControl
                    labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                    placeholder={t("inputs.city")}
                    value={formData?.address.city}
                    name="address.city"
                    error={
                      <ErrorMessage
                        errors={errors}
                        name="address.city"
                        render={({ message }) => (
                          <p className="h7 error-message">{message}</p>
                        )}
                      />
                    }
                    hasError={errors.city}
                    func={{
                      ...register("address.city", {
                        onChange: handleInputChange,
                      }),
                    }}
                  />
                  <InputControl
                    labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                    onChange={handleInputChange}
                    placeholder={t("inputs.zipCode")}
                    value={formData?.address.zip}
                    name="address.zip"
                  />
                </div>
                <InputControl
                  labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                  placeholder={t("inputs.street")}
                  value={formData?.address.street}
                  name="address.street"
                  error={
                    <ErrorMessage
                      errors={errors}
                      name="address.street"
                      render={({ message }) => (
                        <p className="h7 error-message">{message}</p>
                      )}
                    />
                  }
                  hasError={errors.street}
                  func={{
                    ...register("address.street", {
                      onChange: handleInputChange,
                    }),
                  }}
                />
                <div className="AdminBusinessMapInputContainer">
                  <div
                    className={cx("AdminBusinessMapInput", {
                      hasError: errors?.address?.googleLink,
                    })}
                  >
                    <a
                      // href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="MapIcon"
                    >
                      <GoogleMapIcon />
                    </a>
                    <InputControl
                      labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                      placeholder={t("inputs.map")}
                      value={formData?.address.googleLink}
                      name="address.googleLink"
                      noLabelFloating
                      func={{
                        ...register("address.googleLink", {
                          onChange: handleInputChange,
                        }),
                      }}
                    />
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name="address.googleLink"
                    render={({ message }) => (
                      <p className="h7 error-message">{message}</p>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="AdminBusinessInfoAboutFormGroup">
              <h2 className="AdminBusinessInfoAboutFormGroupTitle SemiBold">
                {t("common.contact")}
              </h2>
              <div className="InputGroups">
                <div className="AdminBusinessInfoContactData">
                  {formData?.contact &&
                    Object.keys(formData.contact).map((contact, index) => {
                      const contactData = formData.contact[contact];
                      // console.log(contact);
                      return (
                        <Contact
                          contact={contact}
                          key={index}
                          contactValue={contactData.value || ""}
                          func={{
                            ...register(`contact.${contact}`, {
                              onChange: (e) => handleOnEditContact(e, contact),
                            }),
                          }}
                          control={control}
                          hasError={errors?.contact?.[contact]}
                          error={
                            <ErrorMessage
                              errors={errors}
                              name={`contact.${contact}`}
                              render={({ message }) => (
                                <p className="h7 error-message">{message}</p>
                              )}
                            />
                          }
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            {/*<div className="AdminBusinessInfoAboutPrinters">*/}
            {/*  <div className="AdminBusinessInfoAboutPrintersTitle">*/}
            {/*    <h5 className="AdminBusinessInfoAboutFormGroupTitle Medium">*/}
            {/*      {t("business.printers")}*/}
            {/*    </h5>*/}
            {/*    <AddNewButton*/}
            {/*      onClick={handleAddNewPrint}*/}
            {/*      type={ENUMS_ADD_NEW_BUTTON.types.TYPE_B}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*  {formData.printers.length > 0 ? (*/}
            {/*    <div className="AdminBusinessInfoAboutPrintersList">*/}
            {/*      <Tag*/}
            {/*        items={formData.printers}*/}
            {/*        type={ENUMS_TAG.types.TYPE_C}*/}
            {/*        onRemove={(option) => handleOnDeletePrinter(option.id)}*/}
            {/*        isWrapped*/}
            {/*        onClick={handleOnEditPrinter}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  ) : (*/}
            {/*    <h6 className="AdminBusinessInfoAboutPrintersListMessage Medium">*/}
            {/*      {t("emptyStates.noPrinters")}*/}
            {/*    </h6>*/}
            {/*  )}*/}
            {/*</div>*/}
          </div>
          <div className="AdminBusinessInfoAboutFormPart">
            <div className="inline AdminBusinessInfoAboutFormGroup">
              <h2 className="AdminBusinessInfoAboutFormGroupTitle SemiBold">
                {t("image.images")}
              </h2>
              <div className="AdminBusinessInfoAboutFormGroupInline">
                <div className="AdminBusinessInfoAboutImagesLogoBackground">
                  <div className="AdminBusinessInfoAboutImagesLogo">
                    <h6 className="AdminBusinessInfoAboutImagesTitle Regular">
                      {t("common.logo")}
                    </h6>
                    <div className="AdminBusinessInfoAboutImagesLogoAdd">
                      <AddPhoto
                        onFileSelect={(selectedImage) =>
                          handleOnSelectSingleImage(
                            selectedImage,
                            IMAGES_NAMES.logo
                          )
                        }
                        hasImage={!!formData?.images.logo}
                        images={createDOBucketName(formData?.images.logo)}
                        onRemoveImage={() =>
                          handleOnRemoveSingleImage(IMAGES_NAMES.logo)
                        }
                        allContainerClickable={true}
                        type={ENUMS.types.TYPE_A}
                      />
                    </div>
                  </div>
                  <div className="AdminBusinessInfoAboutImagesBackground">
                    <h6 className="AdminBusinessInfoAboutImagesTitle Regular">
                      {t("image.background")}
                    </h6>
                    <div className="AdminBusinessInfoAboutImagesBackgroundAdd">
                      <AddPhoto
                        onFileSelect={(selectedImage) =>
                          handleOnSelectSingleImage(
                            selectedImage,
                            IMAGES_NAMES.background
                          )
                        }
                        hasImage={!!formData?.images.background}
                        images={createDOBucketName(formData?.images.background)}
                        onRemoveImage={() =>
                          handleOnRemoveSingleImage(IMAGES_NAMES.background)
                        }
                        allContainerClickable={true}
                        type={ENUMS.types.TYPE_A}
                      />
                    </div>
                  </div>
                </div>
                <div className="AdminBusinessInfoAboutImagesOther">
                  <h6 className="AdminBusinessInfoAboutImagesTitle Regular">
                    {t("image.other")}
                  </h6>
                  <div className="AdminBusinessInfoAboutImagesOtherAdd">
                    <AddPhoto
                      onFileSelect={handleOnSelectOtherPhotos}
                      images={formData?.images?.other.map((image) =>
                        createDOBucketName(image)
                      )}
                      onRemoveImage={(photo) => handleOnRemoveOtherImage(photo)}
                      hasImage={formData?.images?.other.length > 0}
                      allContainerClickable={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="AdminBusinessInfoWorkingHours">
              <h2 className="AdminBusinessInfoAboutFormGroupTitle SemiBold">
                {t("business.workingHours")}
              </h2>
              <WorkingHours
                workingHours={formData?.workingHours}
                onSwitch={handleOnSwitchIsWorking}
                handleOnChangeTime={handleOnChangeTime}
              />
            </div>
          </div>
        </form>
      </div>
      {/*<AddItemModal*/}
      {/*  mainElementRef={mainElementRefPrinter}*/}
      {/*  setOpenSlide={setOpenSlidePrinter}*/}
      {/*  title={t("business.printer")}*/}
      {/*  item={selectedPrinter}*/}
      {/*  openSlide={openSlidePrinter}*/}
      {/*  onSave={handleOnSavePrinter}*/}
      {/*  onDelete={handleOnDeletePrinter}*/}
      {/*/>*/}
    </div>
  );
};

export default AdminBusiness;
