import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useValidationSchema } from "utils/hooks/useValidationSchema";
import useCountdown from "utils/hooks/useCountdown";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, FormProvider } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { getUserRoutesByRole, handleOnAsyncSuccess } from "utils/helpers";
import { signin, resendCode } from "redux/actions/userAction";

import { resetAllReduxStores } from "utils/general";
import Login from "components/admin/elements/login/Login";
import ResendPassword from "components/admin/elements/resend-password/ResendPassword";
import EmailOrPhone from "components/elements/email-or-phone/EmailOrPhone";
import Modal from "components/modal/Modal";
import SelectUserBusiness from "components/admin/modal/select-user-business/SelectUserBusiness";

import { ROLES } from "utils/constants/global";
import { ROUTE_NAME } from "utils/constants/routes";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import { STORE_NAMES } from "utils/constants/redux";
import { SIGN_IN_METHOD } from "../sign-in/SignIn";

import "./ForgotPassword.scss";

export const ACTIVE_WINDOW = {
  SEND_PASSWORD: "SEND_PASSWORD",
  VERIFY_PASSWORD: "VERIFY_PASSWORD",
};

const ForgotPassword = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();

  const [activeWindow, setActiveWindow] = useState(ACTIVE_WINDOW.SEND_PASSWORD);
  const [userBusinesses, setUserBusinesses] = useState([]);
  const [showUserBusinessModal, setShowUserBusinessModal] = useState(false);
  const [counter, setCounter] = useCountdown(0);
  const [signInMethod, setSignInMethod] = useState(SIGN_IN_METHOD.phoneNumber);

  const schemas = useValidationSchema(t);

  const isLoadingResend = useSelector((state) => state[STORE_NAMES.user])
    .thunkAPIStates?.resendCode;
  const isLoadingSignIn = useSelector((state) => state[STORE_NAMES.user])
    .thunkAPIStates?.signin;

  const dispatch = useDispatch();

  const methods = useForm({
    resolver: zodResolver(
      schemas.forgotPasswordSchema(activeWindow, signInMethod)
    ),
    criteriaMode: "all",
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    getValues,
  } = methods;

  useEffect(() => {
    resetAllReduxStores(dispatch);
  }, [dispatch]);

  const handlePrev = () => {
    switch (activeWindow) {
      case ACTIVE_WINDOW.SEND_PASSWORD:
        navigate(ROUTE_NAME.signIn);
        break;
      case ACTIVE_WINDOW.VERIFY_PASSWORD:
        setActiveWindow(ACTIVE_WINDOW.SEND_PASSWORD);
        setCounter(0);
        break;
      default:
        break;
    }
  };

  const handleOnSubmit = async () => {
    try {
      const response = await dispatch(
        resendCode({
          userData: {
            emailOrPhone:
              signInMethod === SIGN_IN_METHOD.email
                ? getValues(SIGN_IN_METHOD.email)
                : getValues(SIGN_IN_METHOD.phoneNumber).replace(/\+/g, ""),
          },
          method: signInMethod,
        })
      );

      if (response.error) {
        const errorData = handleAPIErrorMessage(response.payload);
        if (errorData) {
          const { field, errorMessage } = errorData;
          setError(field, {
            type: "manual",
            message: errorMessage,
          });
        }
      } else {
        if (activeWindow === ACTIVE_WINDOW.VERIFY_PASSWORD) {
          setCounter(60);
        }
        setActiveWindow(ACTIVE_WINDOW.VERIFY_PASSWORD);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleVerifyForm = async () => {
    try {
      const response = await dispatch(
        signin({
          userData: {
            emailOrPhone:
              signInMethod === SIGN_IN_METHOD.email
                ? getValues(SIGN_IN_METHOD.email)
                : getValues(SIGN_IN_METHOD.phoneNumber).replace(/\+/g, ""),
            password: getValues("password"),
          },
          method: signInMethod,
        })
      );
      if (response.error) {
        const errorData = handleAPIErrorMessage(response.payload);
        if (errorData) {
          const { field, errorMessage } = errorData;
          setError(field, {
            type: "manual",
            message: errorMessage,
          });
        }
      } else {
        handleOnAsyncSuccess(t("toastMessages.success.login"));
        const userRoutesInfo = getUserRoutesByRole(
          response.payload?.user?.roles[0]?.name
        );

        const isAdminRole = userRoutesInfo.role === ROLES.admin.name;

        if (isAdminRole) {
          navigate(
            `${ROUTE_NAME.admin}${userRoutesInfo.firstRouteAfterSignIn}`,
            {
              replace: true,
            }
          );
        } else {
          setUserBusinesses(response.payload?.user?.businesses);
          setShowUserBusinessModal(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="ForgotPassword">
      {activeWindow === ACTIVE_WINDOW.SEND_PASSWORD ? (
        <Login
          title={t("login.forgotPassword.title")}
          subTitle={t("login.forgotPassword.subTitle")}
          handlePrev={handlePrev}
          isPrevButtonVisible
        >
          <form
            className="ForgotPasswordForm"
            onSubmit={(e) => e.preventDefault()}
          >
            <FormProvider {...methods}>
              <EmailOrPhone
                signInMethod={signInMethod}
                setSignInMethod={setSignInMethod}
                className="ForgotPasswordFormInput"
              />
            </FormProvider>

            <PrimaryButton
              onClick={handleSubmit(handleOnSubmit)}
              text={t("login.forgotPassword.sendPassword")}
              type={PRIMARY_BUTTON_ENUMS.types.TYPE_A}
              className="ForgotPasswordFormButton"
              isLoading={isLoadingResend}
            />
          </form>
        </Login>
      ) : (
        <Login
          title={""}
          subTitle={""}
          handlePrev={handlePrev}
          isPrevButtonVisible
        >
          <form
            className="VerifyPasswordForm"
            onSubmit={(e) => e.preventDefault()}
          >
            <h6 className="Medium">
              {t("login.signUp.passwordSentTo")}{" "}
              <span className="Bold">{getValues(signInMethod)}</span>
            </h6>
            <InputControl
              type="password"
              name="password"
              labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
              placeholder="Password"
              required
              className="VerifyPasswordFormInput"
              {...register("password")}
              hasError={errors.password}
              error={
                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
            />
            <ResendPassword
              onClick={handleOnSubmit}
              isLoading={isLoadingResend}
              counter={counter}
            />
            <PrimaryButton
              onClick={handleSubmit(handleVerifyForm)}
              text={t("buttons.verify")}
              className="VerifyPasswordFormButton"
              type={PRIMARY_BUTTON_ENUMS.types.TYPE_A}
              isLoading={isLoadingSignIn}
            />
          </form>
        </Login>
      )}
      {showUserBusinessModal && (
        <Modal
          openSlide={showUserBusinessModal}
          body={
            <SelectUserBusiness
              userBusinesses={userBusinesses}
              title={t("business.selectTitle")}
              subtitle={t("business.selectSubTitle")}
            />
          }
        />
      )}
    </div>
  );
};

export default ForgotPassword;
