import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import useWindowSize, { BREAKPOINT_NAMES } from "utils/hooks/useWindowSize";
import AdminBodyController from "pages/admin/admin-body/AdminBodyController";
import useAdminMetaDataUpdater from "utils/hooks/useAdminMetaDataUpdater";
import useInventoryUpdater from "utils/hooks/useInventoryUpdater";
import { STORE_NAMES } from "utils/constants/redux";
import { ROUTE_NAME } from "utils/constants/routes";
import AdminSidebarController from "./admin-sidebar/AdminSidebarController";
import useTopicUpdater from "utils/hooks/useTopicUpdater";
import useOrderNotificationSound from "utils/hooks/useOrderNotificationSound";
import useSetUserLanguage from "utils/hooks/useSetUserLanguage";
import { ROLES } from "utils/constants/global";
import AdminBlock from "components/admin/admin-block/AdminBlock";

import "./AdminController.scss";

const AdminController = () => {
  useAdminMetaDataUpdater();
  useInventoryUpdater();
  useTopicUpdater();
  useSetUserLanguage();
  useOrderNotificationSound();
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const activeUser = useSelector((state) => state[STORE_NAMES.user]).user;

  const { isScreenSize } = useWindowSize();
  const isMobile = isScreenSize(BREAKPOINT_NAMES.mobile);

  const { isAdminDashboardPinLocked } = useSelector(
    (state) => state[STORE_NAMES.app]
  );

  const navigate = useNavigate();

  useEffect(() => {
    isAdminDashboardPinLocked &&
      navigate(`${ROUTE_NAME.admin}${ROUTE_NAME.lockScreen}`);
  }, [isAdminDashboardPinLocked]);

  if (business?.isArchived && activeUser.roles[0]?.name !== ROLES.admin.name) {
    return <AdminBlock business={business} />;
  }

  return (
    <div className={`AdminController ${isMobile ? "isMobile" : "isDesktop"}`}>
      <AdminSidebarController />
      <AdminBodyController />
    </div>
  );
};

export default AdminController;
