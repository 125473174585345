import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { DeviceFrameset } from "react-device-frameset";
import { GuestWebsocketContextProvider } from "utils/context-api/GuestWebsocketContext";
import { PARAM_NAME, ROUTE_NAME } from "utils/constants/routes";
import ClientPagesController from "pages/client/ClientPagesController";
import MenuItem from "pages/client/menu-item/MenuItem";
import Basket from "pages/client/basket/Basket";
import Business from "pages/client/business/Business";
import Menu from "pages/client/menu/Menu";
import Dashboard from "pages/client/dashboard/Dashboard";
import useWindowSize from "utils/hooks/useWindowSize";
import AllBusinesses from "pages/client/all-businesses/AllBusinesses";
import GuestChatController from "components/elements/chat/GuestChatController";
import MascotDashboard from "components/mascot/mascot-dashboard/MascotDashboard";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "pages/common/error-boundary/ErrorBoundary";
import OfflineMenu from "pages/common/offline-menu/OfflineMenu";
import FunZoneHomePage from "pages/client/fun-zone/FunZoneHomePage";
import FunZoneProfile from "pages/client/fun-zone/fz-profile/FunZoneProfile";
import FunZonePlayGame from "pages/client/fun-zone/fz-playgame/FunZonePlayGame";
import FunZoneLeaderBoard from "pages/client/fun-zone/fz-leaderboard/FunZoneLeaderBoard";
import useGuestRouteManager from "utils/hooks/useGuestRouteManager";
import MyReservations from "./client/reservation/my-reservations/MyReservations";
import CreateReservation from "./client/reservation/create-reservation/CreateReservation";
import WheelOfFortune from "components/wheel-of-fortune/WheelOfFortune";

const GuestRoutes = () => {
  useGuestRouteManager();

  // TODO Ilgar check where it is needed. Relocate this to better place ??
  useEffect(() => {
    if (window.innerWidth < 1000)
      document.documentElement.style.setProperty(
        "--width-inner-width",
        window.innerWidth
      );
    else {
      document.documentElement.style.setProperty("--width-inner-width", 500);
    }
  }, [window.innerWidth]);

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <GuestWebsocketContextProvider>
        <Routes>
          <Route
            path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}`}
            element={<ClientPagesController />}
          >
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.menu}`}
              element={<Menu />}
            />

            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItem}${PARAM_NAME.menuItemId}`}
              element={<MenuItem />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.basket}`}
              element={<Basket />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.info}`}
              element={<Business />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.dashboard}`}
              element={<Dashboard />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.chat}`}
              element={<GuestChatController />}
            />
          </Route>
          <Route path={ROUTE_NAME.allBusinesses} element={<AllBusinesses />} />
          <Route
            path={`${ROUTE_NAME.reservation}${ROUTE_NAME.myReservations}`}
            element={<MyReservations />}
          />
          <Route
            path={`${ROUTE_NAME.reservation}${ROUTE_NAME.createReservation}`}
            element={<CreateReservation />}
          />
          <Route path={`${ROUTE_NAME.funZone}`}>
            <Route
              path={`${ROUTE_NAME.funZone}${ROUTE_NAME.funZoneHomePage}`}
              element={<FunZoneHomePage />}
            />
            <Route
              path={`${ROUTE_NAME.funZone}${ROUTE_NAME.funZoneProfile}`}
              element={<FunZoneProfile />}
            />
            <Route
              path={`${ROUTE_NAME.funZone}${ROUTE_NAME.funZoneLeaderboard}`}
              element={<FunZoneLeaderBoard />}
            />
            <Route
              path={`${ROUTE_NAME.funZone}${ROUTE_NAME.funZonePlayGame}`}
              element={<FunZonePlayGame />}
            />
          </Route>
          <Route
            path="*"
            element={
              <Navigate
                to={`${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`}
              />
            }
          />
          <Route path={ROUTE_NAME.offlineMenu} element={<OfflineMenu />} />
        </Routes>
        <MascotDashboard />
        <WheelOfFortune />
      </GuestWebsocketContextProvider>
    </ErrorBoundary>
  );
};

const AppRouterGuest = () => {
  const { size } = useWindowSize();

  if (size.width >= 1000) {
    return (
      <div className="MockUp">
        <DeviceFrameset device="iPhone X">
          <GuestRoutes />
        </DeviceFrameset>
      </div>
    );
  } else {
    return <GuestRoutes />;
  }
};

export default AppRouterGuest;
