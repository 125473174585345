import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import { STORE_NAMES } from "utils/constants/redux";
import ReservationDetail from "components/cards/reservation-detail/ReservationDetail";
import SortingOptions from "components/elements/sorting-options/SortingOptions";
import EmptyState from "components/admin/empty-state/EmptyState";
import PendingEmptyIcon from "assets/icons/reserve/PendingEmpty.svg";
import { findZoneOfTable, formatReservationTimeRange } from "utils/helpers";

import "./ReservationList.scss";

const ReservationList = ({
  title,
  reservations,
  setSelectedReservations,
  setOpenSlide,
  updateReservation,
  editedReservations,
}) => {
  const { t } = useTranslation();
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const reservationStatus = useSelector(
    (state) => state[STORE_NAMES.app].enums.reservationStatus
  );

  const businessId = business?.id;

  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const [sortType, setSortType] = useState({
    guestNumber: null,
    requestTime: null,
    startDate: null,
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = String(date.getUTCFullYear()).slice(-2);

    return `${day}/${month}/${year}`;
  };

  const getTimeDifference = (requestDateTime) => {
    const now = new Date();
    const requestTime = new Date(requestDateTime);
    const differenceInMs = now - requestTime;

    const minutes = Math.floor(differenceInMs / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return days === 1
        ? t("reservation.oneDay")
        : `${days} ${t("reservation.day")}`;
    }

    if (hours > 0) {
      const remainingMinutes = minutes % 60;
      return hours === 1
        ? `1${t("reservation.hour")} ${
            remainingMinutes > 0
              ? `${remainingMinutes}${t("reservation.minute")}`
              : ""
          }`
        : `${hours}${t("reservation.hour")} ${
            remainingMinutes > 0
              ? `${remainingMinutes}${t("reservation.minute")}`
              : ""
          }`;
    }

    if (minutes > 0) {
      return minutes === 1
        ? `1 ${t("reservation.minute")}`
        : `${minutes} ${t("reservation.minute")}`;
    }

    return t("reservation.justNow");
  };

  const sortedReservations = useMemo(() => {
    return [...reservations].sort((a, b) => {
      if (sortType.guestNumber) {
        return sortType.guestNumber === "asc"
          ? a.guestsCount - b.guestsCount
          : b.guestsCount - a.guestsCount;
      }
      if (sortType.requestTime) {
        return sortType.requestTime === "asc"
          ? new Date(a.requestDateTime) - new Date(b.requestDateTime)
          : new Date(b.requestDateTime) - new Date(a.requestDateTime);
      }
      if (sortType.startDate) {
        return sortType.startDate === "asc"
          ? new Date(a.startDateTime) - new Date(b.startDateTime)
          : new Date(b.startDateTime) - new Date(a.startDateTime);
      }
      return 0;
    });
  }, [reservations, sortType]);

  const onEditHandler = (reservation) => {
    setOpenSlide(true);
    setSelectedReservations(reservation);
  };
  const onUpdateStatus = (reservation) => {
    updateReservation(businessId, [reservation]);
  };
  return (
    <div
      className={cx("ReservationList", {
        editMode: editedReservations.length > 0,
      })}
    >
      <div className="ReservationListTitle">
        <h3 className="SemiBold">
          {title} ({reservations.length})
        </h3>
      </div>
      {reservations.length > 0 && (
        <div>
          <div className="ReservationStats">
            <div>
              <SortingOptions
                name="guestNumber"
                title={t("reservation.guestNumber")}
                sortType={sortType.guestNumber}
                setSortType={setSortType}
              />
            </div>
            <div>
              <SortingOptions
                name="requestTime"
                title={t("reservation.waitingTime")}
                sortType={sortType.requestTime}
                setSortType={setSortType}
              />
            </div>
            <div>
              <SortingOptions
                name="startDate"
                title={t("reservation.startDate")}
                sortType={sortType.startDate}
                setSortType={setSortType}
              />
            </div>
          </div>
          <div className="ReservationListContent">
            {sortedReservations.map((reservation) => {
              const { startTime, endTime } = formatReservationTimeRange(
                reservation.startDateTime,
                reservation.endDateTime
              );
              return (
                <ReservationDetail
                  key={reservation.id}
                  status={reservation.status}
                  comment={reservation.guestComment}
                  guestCount={reservation.guestsCount}
                  guestName={reservation.guestName}
                  guestPhone={reservation.guestPhone}
                  since={getTimeDifference(reservation.requestDateTime)}
                  date={formatDate(reservation.startDateTime)}
                  time={`${startTime} - ${endTime}`}
                  zone={findZoneOfTable(zones, reservation?.table?.id)}
                  tableName={reservation?.table?.name}
                  onEditHandler={() => onEditHandler(reservation)}
                  onConfirmHandler={() =>
                    onUpdateStatus({
                      id: reservation.id,
                      status: reservationStatus.confirmed,
                      tableId: reservation.table.id,
                      guestId: reservation?.guest?.id,
                    })
                  }
                  onDenyHandler={() =>
                    onUpdateStatus({
                      id: reservation.id,
                      status: reservationStatus.denied,
                      tableId: reservation.table.id,
                      guestId: reservation?.guest?.id,
                    })
                  }
                />
              );
            })}
          </div>
        </div>
      )}
      {reservations.length === 0 && (
        <EmptyState
          icon={PendingEmptyIcon}
          description={t("emptyStates.noPendingReservations")}
          isAdmin={true}
        />
      )}
    </div>
  );
};
ReservationList.propTypes = {
  /**
   * Title to display at the top of the reservation list.
   */
  title: PropTypes.string.isRequired,

  /**
   * Array of reservation objects to display.
   */
  reservations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      guestsCount: PropTypes.number.isRequired,
      guestName: PropTypes.string,
      guestPhone: PropTypes.string,
      guestComment: PropTypes.string,
      status: PropTypes.string.isRequired,
      requestDateTime: PropTypes.string.isRequired,
      startDateTime: PropTypes.string.isRequired,
      endDateTime: PropTypes.string.isRequired,
      table: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        name: PropTypes.string,
      }),
      guest: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    })
  ).isRequired,

  /**
   * Function to set the selected reservation when editing.
   */
  setSelectedReservations: PropTypes.func.isRequired,

  /**
   * Function to open the slide for reservation details.
   */
  setOpenSlide: PropTypes.func.isRequired,

  /**
   * Function to update the reservation status.
   */
  updateReservation: PropTypes.func.isRequired,

  /**
   * Array of edited reservations.
   */
  editedReservations: PropTypes.array.isRequired,
};

export default ReservationList;
