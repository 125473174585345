import { z } from "zod";
import { PhoneNumberUtil } from "google-libphonenumber";
import { SIGN_IN_METHOD } from "pages/common/login/sign-in/SignIn";
import { ACTIVE_WINDOW } from "pages/common/login/forgot-password/ForgotPassword";

export const useValidationSchema = (t) => {
  const phoneNumberUtil = PhoneNumberUtil.getInstance();

  const isPhoneNumberValid = (phone) => {
    try {
      return phoneNumberUtil.isValidNumber(
        phoneNumberUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      return false;
    }
  };

  const isObjectEmpty = (obj) => {
    const result = z.object({}).strict().safeParse(obj);
    return result.success;
  };

  const requiredNumberSchema = z
    .number({
      required_error: t("errorMessages.input.generalRequired"),
    })
    .int(t("errorMessages.input.reservation.maxGuestCount"))
    .positive(t("errorMessages.input.minNumber"))
    .max(
      1000000000000,
      t("errorMessages.input.maxNumber").replace("{{max}}", "1000000000000")
    );

  const requiredNumberSchemaIntegerOnly = z
    .number({
      required_error: t("errorMessages.input.generalRequired"),
    })
    .int(t("errorMessages.input.reservation.maxGuestCount"))
    .positive(t("errorMessages.input.minNumber"))
    .max(
      1000000000000,
      t("errorMessages.input.maxNumber").replace("{{max}}", "1000000000000")
    );

  const optionalIntegerOnlySchema = z
    .number({
      required_error: t("errorMessages.input.generalRequired"),
    })
    .int(t("errorMessages.input.qrAndTable.maxSeat"))
    .max(
      1000000000000,
      t("errorMessages.input.maxNumber").replace("{{max}}", "1000000000000")
    )
    .positive(t("errorMessages.input.minNumber"))
    .optional();

  const optionalNumberSchema = z
    .number({
      required_error: t("errorMessages.input.generalRequired"),
    })
    .min(0, t("errorMessages.input.minNumber"))
    .max(
      1000000000000,
      t("errorMessages.input.maxNumber").replace("{{max}}", "1000000000000")
    )
    .optional();

  const optionalNumberSchemaForModificationOption = z
    .number({
      required_error: t("errorMessages.input.generalRequired"),
    })
    .max(
      1000000000000,
      t("errorMessages.input.maxNumber").replace("{{max}}", "1000000000000")
    )
    .optional();

  const optionalStringSchema = z
    .string()
    .max(100, t("errorMessages.input.maxCharacter").replace("{{max}}", "100"))
    .optional()
    .nullable();

  const businessDescriptionSchema = z
    .string()
    .max(1000, t("errorMessages.input.maxCharacter").replace("{{max}}", "1000"))
    .optional();

  const menuItemDescriptionSchema = z
    .string()
    .max(250, t("errorMessages.input.maxCharacter").replace("{{max}}", "250"))
    .optional();

  const menuItemPriceSchema = z
    .number({
      required_error: t("errorMessages.input.generalRequired"),
    })
    .min(0, t("errorMessages.input.minNumber"))
    .max(
      1000000000000,
      t("errorMessages.input.maxNumber").replace("{{max}}", "1000000000000")
    );

  const requiredStringSchema = z
    .string({
      required_error: t("errorMessages.input.generalRequired"),
    })
    .min(1, t("errorMessages.input.generalRequired"))
    .max(100, t("errorMessages.input.maxCharacter").replace("{{max}}", "100"))
    .refine((value) => {
      return value.trim() !== "";
    }, t("errorMessages.input.generalRequired"));

  const requiredIdSchemaForDropdown = z.number({
    required_error: t("errorMessages.input.generalRequired"),
  });

  const requiredPhoneSchemaForReservation = z
    .string({
      required_error: t("errorMessages.input.generalRequired"),
    })
    .min(13, t("errorMessages.input.generalRequired"))
    .max(13, t("errorMessages.input.maxCharacter").replace("{{max}}", "13"))
    .refine((value) => {
      return value.trim() !== "";
    }, t("errorMessages.input.generalRequired"));

  const requiredStringSchemaForMenuItem = z
    .string()
    .max(100, t("errorMessages.input.maxCharacter").replace("{{max}}", "100"));

  const optionalInstagramURLSchema = z
    .string()
    .optional()
    .refine((value) => {
      if (value) {
        return /^(https?:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9_.]+\/?$/.test(
          value
        );
      }
      return true;
    }, t("errorMessages.input.contact.instagram"));

  const pinCodeSchema = () =>
    z
      .string({
        required_error: t("errorMessages.input.generalRequired"),
      })
      .max(
        6,
        t("errorMessages.input.pinCode.maxDigits").replace("{{max}}", "6")
      )
      .optional()
      .refine((value) => {
        if (value !== "") {
          return /^\d+$/.test(value);
        }
        return true;
      }, t("errorMessages.input.pinCode.notNumeric"));

  const passwordSchema = z
    .string({
      required_error: t("errorMessages.input.generalRequired"),
    })
    .min(6, t("errorMessages.input.minCharacter").replace("{{min}}", "6"))
    .max(100, t("errorMessages.input.maxCharacter").replace("{{max}}", "100"));

  const otpPasswordSchema = z
    .string()
    .min(1, t("errorMessages.input.generalRequired"))
    .length(6, t("errorMessages.input.password.incorrectLength"))
    .refine((value) => {
      if (value !== "") {
        return /^\d+$/.test(value);
      }
      return true;
    }, t("errorMessages.input.password.notNumeric"));

  const serviceFeeSchema = z
    .number()
    .min(0, t("errorMessages.input.business.serviceFeeMin"))
    .max(99, t("errorMessages.input.business.serviceFeeMax"))
    .optional();

  const optionalUrlSchema = z.union([
    z
      .string()
      .url({
        message: t("errorMessages.input.business.url"),
      })
      .nullish(),
    z.literal(""),
  ]);

  const optionalEmailSchema = optionalStringSchema.refine((value) => {
    if (value && value !== "") {
      return /^\S+@\S+\.\S+$/.test(value);
    }

    return true;
  }, t("errorMessages.input.email.incorrect"));

  const optionalPhoneNumberSchema = optionalStringSchema.refine((value) => {
    if (value && value !== "") {
      return isPhoneNumberValid(value);
    }

    return true;
  }, t("errorMessages.input.phoneNumber.incorrect"));

  const requiredEmailSchema = requiredStringSchema.refine((value) => {
    if (value && value !== "") {
      return /^\S+@\S+\.\S+$/.test(value);
    }

    return true;
  }, t("errorMessages.input.email.incorrect"));

  const requiredPhoneNumberSchema = requiredStringSchema.refine((value) => {
    if (value && value !== "") {
      return isPhoneNumberValid(value);
    }

    return true;
  }, t("errorMessages.input.phoneNumber.incorrect"));

  const emailOrPhoneSchema = (signInMethod) =>
    z.object({
      phoneNumber:
        signInMethod === SIGN_IN_METHOD.phoneNumber
          ? requiredPhoneNumberSchema
          : optionalStringSchema,
      email:
        signInMethod === SIGN_IN_METHOD.email
          ? requiredEmailSchema
          : optionalStringSchema,
    });

  const userRoleSchema = z
    .object({
      id: z.number().optional(),
      name: z.string().optional(),
    })
    .refine((value) => {
      return !isObjectEmpty(value);
    }, t("errorMessages.input.generalRequired"));

  const planSchema = z
    .object({
      id: z.number().optional(),
      name: z.string().optional(),
    })
    .refine((value) => {
      return !isObjectEmpty(value);
    }, t("errorMessages.input.generalRequired"));

  const newPlanSchema = z
    .object({
      id: z.number().optional(),
      name: z.string().optional(),
    })
    .refine((value) => {
      return !isObjectEmpty(value);
    }, t("errorMessages.input.generalRequired"));

  const qrTypeSchema = z
    .object({
      id: z.number().optional(),
      name: z.string().optional(),
      value: z.string().optional(),
    })
    .refine((value) => {
      return !isObjectEmpty(value);
    }, t("errorMessages.input.generalRequired"));

  const addItemModalSchema = z.object({
    name: requiredStringSchema,
  });

  const reservationDateAndGuestModalSchema = z.object({
    date: requiredStringSchema,
    time: requiredStringSchema,
  });

  const reservationNameAndPhoneModalSchema = z.object({
    guestName: requiredStringSchema,
    guestPhone: requiredPhoneNumberSchema,
  });
  const reservationGuestCommentSchema = z.object({
    guestComment: menuItemDescriptionSchema,
  });

  const adminReservationModalSchema = z.object({
    guestName: requiredStringSchema,
    guestsCount: requiredNumberSchemaIntegerOnly,
    reservationDay: requiredStringSchema,
    time: requiredStringSchema,
    guestPhone: requiredPhoneNumberSchema,
    zone: requiredIdSchemaForDropdown,
    table: requiredIdSchemaForDropdown,
    duration: requiredStringSchema,
    status: requiredStringSchema,
    guestComment: menuItemDescriptionSchema,
  });

  // Includes the validation of email and phone only if the shouldValidateEmailOrPhone is true
  const conditionalEmailOrPhoneValidation = (
    baseSchema,
    signInMethod,
    shouldValidateEmailOrPhone
  ) => {
    if (shouldValidateEmailOrPhone) {
      return baseSchema.merge(emailOrPhoneSchema(signInMethod));
    }
    return baseSchema;
  };

  const userModalSchema = (signInMethod) =>
    z
      .object({
        firstName: requiredStringSchema,
        lastName: requiredStringSchema,
        pinCode: pinCodeSchema().optional(),
        role: userRoleSchema,
      })
      .merge(emailOrPhoneSchema(signInMethod));

  const requiredQrNameModalSchema = z
    .string({
      required_error: t("errorMessages.input.generalRequired"),
    })
    .min(1, t("errorMessages.input.generalRequired"))
    .max(250, t("errorMessages.input.maxCharacter").replace("{{max}}", "250"))
    .refine((value) => {
      return value.trim() !== "";
    }, t("errorMessages.input.generalRequired"));

  const requiredQrWifeModalSchema = z
    .string({
      required_error: t("errorMessages.input.generalRequired"),
    })
    .min(1, t("errorMessages.input.generalRequired"))
    .max(50, t("errorMessages.input.maxCharacter").replace("{{max}}", "50"))
    .refine((value) => {
      return value.trim() !== "";
    }, t("errorMessages.input.generalRequired"));

  const optionalQrWifeModalSchema = z
    .string()
    .max(50, t("errorMessages.input.maxCharacter").replace("{{max}}", "50"))
    .optional()
    .nullable();

  const qrModalSchema = (formData, QR_TYPES) =>
    z.object({
      name: requiredQrNameModalSchema,
      type: qrTypeSchema,
      wiFiName:
        formData?.type?.value === QR_TYPES.WiFi
          ? requiredQrWifeModalSchema
          : optionalQrWifeModalSchema,
      wiFiPassword:
        formData?.type?.value === QR_TYPES.WiFi
          ? requiredQrWifeModalSchema
          : optionalQrWifeModalSchema,
    });

  const tableModalSchema = z.object({
    name: requiredStringSchema,
    maxSeat: optionalIntegerOnlySchema,
    minDeposit: optionalNumberSchema,
  });

  const businessSchema = (businessName) =>
    z.object({
      name: !businessName ? requiredStringSchema : optionalStringSchema,
      description: businessDescriptionSchema,
      serviceFee: serviceFeeSchema,
      address: z.object({
        city: optionalStringSchema,
        street: optionalStringSchema,
        googleLink: optionalUrlSchema,
      }),
      contact: z.object({
        instagram: optionalInstagramURLSchema,
        phone: optionalPhoneNumberSchema,
        whatsapp: optionalPhoneNumberSchema,
        gmail: optionalEmailSchema,
      }),
    });

  const businessModalSchema = (signInMethod, isEditable) => {
    const baseBusinessSchema = z.object({
      name: optionalStringSchema,
      firstName: isEditable ? optionalStringSchema : requiredStringSchema,
      lastName: isEditable ? optionalStringSchema : requiredStringSchema,
      plan: planSchema,
    });

    return conditionalEmailOrPhoneValidation(
      baseBusinessSchema,
      signInMethod,
      !isEditable
    );
  };

  const signUpSchema = (signInMethod) =>
    z
      .object({
        firstName: requiredStringSchema,
        lastName: requiredStringSchema,
        businessName: requiredStringSchema,
        plan: newPlanSchema,
        // agreeTerms: z.boolean().refine((value) => value, t("errorMessages.input.generalRequired")),
      })
      .merge(emailOrPhoneSchema(signInMethod));

  const signInSchema = (signInMethod) =>
    z
      .object({
        password: passwordSchema,
      })
      .merge(emailOrPhoneSchema(signInMethod));

  const confirmPasswordSchema = z.object({
    password: otpPasswordSchema,
  });

  const forgotPasswordSchema = (activeWindow, signInMethod) => {
    const baseForgotPasswordSchema = z.object({
      password:
        activeWindow === ACTIVE_WINDOW.VERIFY_PASSWORD
          ? otpPasswordSchema
          : z.string().optional(),
    });

    return conditionalEmailOrPhoneValidation(
      baseForgotPasswordSchema,
      signInMethod,
      activeWindow === ACTIVE_WINDOW.SEND_PASSWORD
    );
  };

  const setUserPinCodeSchema = z
    .object({
      pinCode: z
        .string({
          required_error: t("errorMessages.input.pinCode.skipOrSetNow"),
        })
        .min(1, t("errorMessages.input.pinCode.skipOrSetNow"))
        .max(
          6,
          t("errorMessages.input.pinCode.maxDigits").replace("{{max}}", "6")
        )
        .refine(
          (value) => /^\d+$/.test(value),
          t("errorMessages.input.pinCode.notNumeric")
        ),
      confirmPinCode: z.string().optional(),
    })
    .superRefine(({ pinCode, confirmPinCode }, ctx) => {
      if (pinCode !== confirmPinCode) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("errorMessages.input.pinCode.notMatch"),
          path: ["confirmPinCode"],
        });
      }
    });

  const addMenuItemSchema = (formData) =>
    z
      .object({
        description: menuItemDescriptionSchema,
        priceSell: menuItemPriceSchema,
        priceCost: optionalNumberSchema,
        calories: optionalNumberSchema,
        timeToMake: optionalNumberSchema,
        amount: optionalNumberSchema,
        rate: z
          .number()
          .positive(t("errorMessages.input.minNumber"))
          .optional(),
      })
      .superRefine((values, ctx) => {
        if (formData.amount && !formData.unit) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("errorMessages.input.menu.requiredUnit"),
            path: ["unit"],
          });
        }
        if (formData?.rate.isFixed) {
          if (values.rate > values.priceSell) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t("errorMessages.input.menu.fixedDiscount"),
              path: ["rate"],
            });
          }
        } else if (values.rate < 0 || values.rate > 100) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("errorMessages.input.menu.unFixedDiscount"),
            path: ["rate"],
          });
        }
      });

  const addMenuItemModificationSchema = () =>
    z.object({
      options: {
        priceSell: requiredNumberSchema,
        priceCost: optionalNumberSchema,
        maxNumber: optionalNumberSchema,
      },
    });

  const menuSettingsLanguageSchema = (SETTINGS_OPTIONS) =>
    z.object({
      [SETTINGS_OPTIONS.availableLanguages]: z.array(z.number()).nonempty({
        message: t(
          "errorMessages.input.menuSettings.requiredAvailableLanguages"
        ),
      }),
      [SETTINGS_OPTIONS.language]: z.number({
        message: t("errorMessages.input.menuSettings.requiredDefaultLanguage"),
      }),
    });

  const guestAccountInfoSchema = z.object({
    name: requiredStringSchema,
    phoneNumber: optionalPhoneNumberSchema,
  });

  const editZoneForMapSchema = z.object({
    name: requiredStringSchema,
    width: optionalNumberSchema,
    height: optionalNumberSchema,
  });

  const addDepartmentSchema = z.object({
    name: requiredStringSchema,
  });

  return {
    userModalSchema,
    addItemModalSchema,
    addMenuItemModificationSchema,
    qrModalSchema,
    tableModalSchema,
    businessSchema,
    businessModalSchema,
    signUpSchema,
    signInSchema,
    forgotPasswordSchema,
    confirmPasswordSchema,
    setUserPinCodeSchema,
    addMenuItemSchema,
    requiredStringSchema,
    requiredStringSchemaForMenuItem,
    menuSettingsLanguageSchema,
    requiredPhoneNumberSchema,
    optionalNumberSchema,
    guestAccountInfoSchema,
    editZoneForMapSchema,
    optionalNumberSchemaForModificationOption,
    addDepartmentSchema,
    reservationDateAndGuestModalSchema,
    reservationNameAndPhoneModalSchema,
    reservationGuestCommentSchema,
    adminReservationModalSchema,
  };
};
