export const HEADERS = {
  keys: {
    auth: "Authorization",
  },
  values: {
    authType: "Bearer",
  },
};

export const ENDPOINTS = {
  hello: "/hello",
  sales: "/sales",
  menu: "/menu",
  business: "/business",
  orders: "/orders",
  statistics: "/statistics",
  history: "/history",
  deleteHistory: "/delete-history",
  items: "/items",
};

export const API_PATH_PARAMS = {
  business: "business",
  settings: "settings",
  metadata: "metadata",
  admin: "admin",
  general: "general",
  menus: "menus",
  menuCategories: "menu-categories",
  inventoryCategories: "inventory-categories",
  inventory: "inventory",
  menuItems: "menu-items",
  orders: "orders",
  table: "table",
  assignee: "assignee",
  action: "action",
  qr: "qr",
  reservations: "reservations",
  pending: "pending",
  tables: "tables",
  map: "map",
  tags: "tags",
  auth: "auth",
  login: "login",
  verifyUser: "verify-user",
  signUp: "signup",
  resendCode: "resend-code",
  users: "users",
  guests: "guests",
  zones: "zones",
  info: "info",
  infra: "infra",
  ai: "ai",
  translate: "translate",
};

export const API_PATH_HELPERS = {
  size: "size",
  type: "type",
  qrId: "qrId",
  method: "method",
  sendPinCode: "sendPinCode",
};

export const QUERY_PARAMS = {
  startDate: "startDate",
  endDate: "endDate",
  pagination: {
    size: "pageSize",
    number: "pageNumber",
  },
  sort: {
    dir: "sortDir",
    by: "sortBy",
  },
  zone: "zone",
  staff: "staff",
  orderId: "orderId",
  itemName: "itemName",
  categoryId: "categoryId",
  what: "what",
  sourceLang: "sourceLang",
  targetLangs: "targetLangs",
};

export const QUERY_VALUES = {
  sortDirASC: "ASC",
  sortDirDESC: "DESC",
};
