import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";

import EmailOrPhone from "components/elements/email-or-phone/EmailOrPhone";
import { zodResolver } from "@hookform/resolvers/zod";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { ErrorMessage } from "@hookform/error-message";
import { resetAllReduxStores } from "utils/general";
import { signin } from "redux/actions/userAction";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import { setUserBusiness } from "redux/slices/userStore";
import { ROUTE_NAME } from "utils/constants/routes";
import { getUserRoutesByRole, handleOnAsyncSuccess } from "utils/helpers";
import Login from "components/admin/elements/login/Login";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { ROLES } from "utils/constants/global";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { useKeyPress } from "utils/hooks/useKeyPress";
import { EVENT_CODES } from "utils/constants/events";
import SelectUserBusinessModal from "components/admin/modal/select-user-business/SelectUserBusinessModal";

import "./SignIn.scss";

export const SIGN_IN_METHOD = {
  phoneNumber: "phoneNumber",
  email: "email",
};

const SignIn = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();

  const [signInMethod, setSignInMethod] = useState(SIGN_IN_METHOD.phoneNumber);
  const [selectedBusiness, setSelectedBusiness] = useState(null);

  const schemas = useValidationSchema(t);

  const isLoadingSignIn = useSelector((state) => state[STORE_NAMES.user])
    .thunkAPIStates?.signin;

  const methods = useForm({
    resolver: zodResolver(schemas.signInSchema(signInMethod)),
    criteriaMode: "all",
  });

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = methods;

  const [userBusinesses, setUserBusinesses] = useState([]);
  // const [showUserBusinessModal, setShowUserBusinessModal] = useState(false);
  const [
    showUserBusinessModal,
    setShowUserBusinessModal,
    mainElementShowUserBusinessModal,
  ] = useOutsideClick();

  useEffect(() => {
    resetAllReduxStores(dispatch);
  }, []);

  const handleOnSubmit = async () => {
    const formData = getValues();

    try {
      const response = await dispatch(
        signin({
          userData: {
            emailOrPhone:
              signInMethod === SIGN_IN_METHOD.email
                ? formData.email
                : formData.phoneNumber.replace(/\+/g, ""),
            password: formData.password,
          },
          method: signInMethod,
        })
      );
      if (response.error) {
        const errorData = handleAPIErrorMessage(response.payload);
        if (errorData) {
          const { field, errorMessage } = errorData;
          setError(field, {
            type: "manual",
            message: errorMessage,
          });
        }
      } else {
        handleOnAsyncSuccess(t("toastMessages.success.login"));
        const userRoutesInfo = getUserRoutesByRole(
          response.payload?.user?.roles[0]?.name
        );

        const isAdminRole = userRoutesInfo.role === ROLES.admin.name;

        if (isAdminRole) {
          navigate(
            `${ROUTE_NAME.admin}${userRoutesInfo.firstRouteAfterSignIn}`,
            {
              replace: true,
            }
          );
        } else {
          if (response.payload?.user?.businesses.length > 1) {
            setUserBusinesses(response.payload?.user?.businesses);
            setSelectedBusiness(response.payload?.user?.businesses[0]);
            setShowUserBusinessModal(true);
            return;
          }
          dispatch(
            setUserBusiness({
              businessId: response.payload?.user?.businesses[0].id,
              menuId: response.payload?.user?.businesses[0].menu.id,
            })
          );

          await navigate(
            `${ROUTE_NAME.admin}${userRoutesInfo.firstRouteAfterSignIn}`
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const enterPressed = useKeyPress(EVENT_CODES.enter);

  useEffect(() => {
    if (enterPressed) {
      handleSubmit(handleOnSubmit)();
    }
  }, [enterPressed]);

  return (
    <div className="SignIn">
      <Login
        title={t("login.signIn.title")}
        subTitle={t("login.signIn.subTitle")}
        link={t("login.signUp.title")}
        linkHref={ROUTE_NAME.signUp}
        hasSSO
      >
        <form className="SignInForm" onSubmit={(e) => e.preventDefault()}>
          <div className="SignInFormInputGroups">
            <FormProvider {...methods}>
              <EmailOrPhone
                signInMethod={signInMethod}
                setSignInMethod={setSignInMethod}
              />
            </FormProvider>
            <InputControl
              type="password"
              required
              placeholder={t("inputs.password")}
              name="password"
              {...register("password")}
              hasError={errors.password}
              labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
              error={
                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
            />
          </div>
          <h6
            onClick={() => navigate(`${ROUTE_NAME.forgotPassword}`)}
            className="Medium SignInFormForgotPassword"
          >
            {t("login.forgotPassword.title")}
          </h6>
          <PrimaryButton
            onClick={handleSubmit(handleOnSubmit)}
            text={t("login.signIn.title")}
            type={PRIMARY_BUTTON_ENUMS.types.TYPE_A}
            className="SignInFormButton"
            isLoading={isLoadingSignIn}
          />
        </form>
      </Login>
      {showUserBusinessModal && (
        <SelectUserBusinessModal
          showModal={showUserBusinessModal}
          businessLists={userBusinesses}
          setSelectedBusiness={setSelectedBusiness}
          selectedBusiness={selectedBusiness}
          mainElementBusinessModal={mainElementShowUserBusinessModal}
        />
      )}
    </div>
  );
};

export default SignIn;
