import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Modal from "components/modal/Modal";

import IconButton from "components/buttons/icon-button/IconButton";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import { ReactComponent as MagicPen } from "assets/icons/chat-gpt/MagicPen.svg";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { STORE_NAMES } from "utils/constants/redux";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ErrorMessage } from "@hookform/error-message";
import useAsync from "utils/hooks/useAsync";
import CTAButton from "components/buttons/cta-button/CTAButton";
import { aiTranslate } from "utils/api/services/open-ai";
import TranslateAiDropdown from "components/admin/modal/translate-ai-modal/TranslateAiDropdown";
import { toast } from "react-toastify";
import TranslateWarningModal from "components/admin/modal/translate-warning-modal/TranslateWarningModal";

import "./AdminAiTranslateModal.scss";

const SETTINGS_OPTIONS = {
  language: "language",
  availableLanguages: "availableLanguages",
};

export const DROPDOWN_TYPES = {
  category: "category",
  items: "items",
};

export const AI_TRANSLATE_TYPES = {
  menu: "MENU",
};

const AdminAiTranslateModal = ({ setOpenSlide, openSlide, mainElementRef }) => {
  const { t } = useTranslation();
  const schemas = useValidationSchema(t);
  const allLanguages = useSelector((state) => state[STORE_NAMES.app].languages);
  const { data: menu } = useSelector((state) => state[STORE_NAMES.menu]);
  const businessId = useSelector(
    (state) => state[STORE_NAMES.user].user?.business.id
  );

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedMenuItems, setSelectedMenuItems] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSelectedError, setIsSelectedError] = useState(false);
  const [formData, setFormData] = useState({
    [SETTINGS_OPTIONS.language]: menu?.language?.id,
    [SETTINGS_OPTIONS.availableLanguages]: allLanguages.map((lang) => lang.id),
  });
  const [isTranslationSuccess, setIsTranslationSuccess] = useState(false);

  useEffect(() => {
    setIsSubmitDisabled(
      selectedCategories.length === 0 && selectedMenuItems.length === 0
    );
  }, [selectedCategories, selectedMenuItems]);

  const { execute: executeAiTranslate, loading: isAiTranslateLoading } =
    useAsync(aiTranslate, {
      onError: () => toast.error(t("toastMessages.error.common")),
      onSuccess: () => setIsTranslationSuccess(true),
    });

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      [SETTINGS_OPTIONS.availableLanguages]: allLanguages
        .filter((lang) => lang.id !== prev[SETTINGS_OPTIONS.language])
        .map((lang) => lang.id),
    }));
  }, [allLanguages, formData[SETTINGS_OPTIONS.language]]);

  const menuSettingsLanguageSchema =
    schemas.menuSettingsLanguageSchema(SETTINGS_OPTIONS);

  const {
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm({
    resolver: zodResolver(menuSettingsLanguageSchema),
    criteriaMode: "all",
    mode: "onChange",
  });

  const filteredCategories =
    menu?.categories
      .filter((item) => !item.isArchived)
      .sort((a, b) => a.placeInTheList - b.placeInTheList) || [];

  const filteredItems =
    filteredCategories
      .reduce((acc, category) => [...acc, ...category.menuItems], [])
      .filter((item) => !item.isArchived) || [];

  const sortLanguages = (languages) => languages.sort((a, b) => a - b);
  const mapLanguageIdsToObjects = (languageIds) => {
    return languageIds.map((id) => allLanguages.find((lang) => lang.id === id));
  };

  const watchedLanguage = watch(SETTINGS_OPTIONS.language) || formData.language;
  const watchedAvailableLanguages = watch(SETTINGS_OPTIONS.availableLanguages);
  const watchedLanguageName = allLanguages.find(
    (lang) => lang.id === watchedLanguage
  )?.code;

  const handleAvailableLanguagesChange = (selectedOptions) => {
    const handleOnLanguageRemove = (option) => {
      const updatedLanguages = formData[
        SETTINGS_OPTIONS.availableLanguages
      ].filter((langId) => langId !== option.id);

      setFormData({
        ...formData,
        [SETTINGS_OPTIONS.availableLanguages]: updatedLanguages,
      });

      setValue(SETTINGS_OPTIONS.availableLanguages, updatedLanguages);
    };

    const existingLanguages = formData[
      SETTINGS_OPTIONS.availableLanguages
    ].includes(selectedOptions.id);

    if (existingLanguages) {
      handleOnLanguageRemove(selectedOptions);
    } else {
      const updatedLanguages = sortLanguages([
        ...formData[SETTINGS_OPTIONS.availableLanguages],
        selectedOptions.id,
      ]);

      setFormData({
        ...formData,
        [SETTINGS_OPTIONS.availableLanguages]: updatedLanguages,
      });

      setValue(SETTINGS_OPTIONS.availableLanguages, updatedLanguages);
    }
  };

  const handleOnLanguageChange = (option) => {
    setFormData({
      ...formData,
      [SETTINGS_OPTIONS.language]: option.id,
      [SETTINGS_OPTIONS.availableLanguages]: allLanguages
        .filter((lang) => lang.id !== option.id)
        .map((lang) => lang.id),
    });

    setValue(SETTINGS_OPTIONS.language, option.id);
    setValue(
      SETTINGS_OPTIONS.availableLanguages,
      allLanguages
        .filter((lang) => lang.id !== option.id)
        .map((lang) => lang.id)
    );

    setSelectedCategories([]);
    setSelectedMenuItems([]);
  };

  const handleAiTranslateSubmitForm = () => {
    const filterExcludedCategories = filteredCategories
      .filter((category) => !selectedCategories.includes(category.id))
      .map((category) => category.id);

    const filterExcludedItems = filteredCategories
      .reduce((acc, category) => {
        return [...acc, ...category.menuItems];
      }, [])
      .filter((item) => !selectedMenuItems.includes(item.id))
      .map((item) => item.id);

    let sourceLanguages = allLanguages.find(
      (lang) => lang.id === watchedLanguage
    );

    const targetLanguages = allLanguages
      .filter((lang) =>
        formData[SETTINGS_OPTIONS.availableLanguages].includes(lang.id)
      )
      .map((lang) => lang.code)
      .join(",");

    const sendData = {
      businessId: businessId,
      type: AI_TRANSLATE_TYPES.menu,
      sourceLang: sourceLanguages.code,
      targetLangs: targetLanguages,
      translateData: {
        menuID: menu.id,
        excludeCategories: filterExcludedCategories,
        excludeMenuItems: filterExcludedItems,
      },
    };

    executeAiTranslate(sendData);
  };

  const handleClose = () => {
    if (!isAiTranslateLoading) {
      setOpenSlide(false);
    }
  };

  const AITranslateModalHeader = (
    <div className="AITranslateModalHeader">
      <h3 className="AITranslateModalHeaderTitle SemiBold">
        {t("menu.translate.aiAutoTranslate")}
      </h3>
      <IconButton onClick={handleClose} svgComponent={<IconClose />} />
    </div>
  );

  const AITranslateModalBody = (
    <div className="AITranslateModalBody">
      <h4 className="AITranslateModalBodyTitle SemiBold">
        {t("menu.translate.languageSelection")}
      </h4>
      <div className="AITranslateModalLanguageDropdowns">
        <div className="AITranslateModalLanguageDropdown">
          <Controller
            name={SETTINGS_OPTIONS.language}
            control={control}
            defaultValue={formData[SETTINGS_OPTIONS.language] || null}
            render={() => (
              <Dropdown
                value={
                  allLanguages.find((lang) => lang.id === formData.language) ||
                  {}
                }
                options={allLanguages}
                dropdownWithIcon
                placeholder={t("buttons.source")}
                onChange={handleOnLanguageChange}
                required
                isOptionRequired
                error={
                  <ErrorMessage
                    errors={!watchedLanguage ? errors : {}}
                    name={SETTINGS_OPTIONS.language}
                    render={({ message }) => (
                      <p className="h7 error-message">{message}</p>
                    )}
                  />
                }
              />
            )}
          />
        </div>
        <div className="AITranslateModalLanguageDropdown">
          <Controller
            name={SETTINGS_OPTIONS.availableLanguages}
            control={control}
            defaultValue={formData[SETTINGS_OPTIONS.availableLanguages] || []}
            render={() => (
              <Dropdown
                isMultiple
                value={mapLanguageIdsToObjects(
                  formData[SETTINGS_OPTIONS.availableLanguages]
                )}
                options={
                  allLanguages.filter((lang) => lang.id !== watchedLanguage) ||
                  []
                }
                required
                onChange={handleAvailableLanguagesChange}
                dropdownWithIcon
                placeholder={t("buttons.target")}
                error={
                  <ErrorMessage
                    errors={
                      watchedAvailableLanguages &&
                      watchedAvailableLanguages.length === 0
                        ? errors
                        : {}
                    }
                    name={SETTINGS_OPTIONS.availableLanguages}
                    render={({ message }) => (
                      <p className="h7 error-message">{message}</p>
                    )}
                  />
                }
              />
            )}
          />
        </div>
      </div>
      <div>
        <h4 className="SemiBold">
          {t("menu.category.category")} & {t("menu.item.item")}
        </h4>
        <TranslateAiDropdown
          categories={filteredCategories}
          items={filteredItems}
          name={DROPDOWN_TYPES.category}
          title={t("menu.category.all")}
          excludeCategories={selectedCategories}
          setExcludeCategoriesHandler={setSelectedCategories}
          watchedLanguageName={watchedLanguageName}
          isItemSelectedError={isSelectedError}
          setIsItemSelectedError={setIsSelectedError}
        />
        <TranslateAiDropdown
          categories={filteredCategories}
          items={filteredItems}
          name={DROPDOWN_TYPES.items}
          title={t("menu.item.all")}
          excludeItems={selectedMenuItems}
          setExcludeItemsHandler={setSelectedMenuItems}
          watchedLanguageName={watchedLanguageName}
          isItemSelectedError={isSelectedError}
          setIsItemSelectedError={setIsSelectedError}
        />
      </div>
    </div>
  );

  const AITranslateModalFooter = (
    <CTAButton
      onClick={handleAiTranslateSubmitForm}
      name={t("menu.translate.magicTranslate")}
      icon={<MagicPen />}
      isLoading={isAiTranslateLoading}
      disabled={isSubmitDisabled}
      type={isSubmitDisabled ? "TYPE_D" : "TYPE_F"}
      className="AITranslateModalFooterButton"
    />
  );

  return isTranslationSuccess ? (
    <TranslateWarningModal
      warningModalOpenSlide={openSlide}
      warningModalRef={mainElementRef}
      warningModalSetOpenSlide={setOpenSlide}
    />
  ) : (
    <Modal
      footer={AITranslateModalFooter}
      header={AITranslateModalHeader}
      body={AITranslateModalBody}
      openSlide={openSlide}
      mainElementRef={isAiTranslateLoading ? null : mainElementRef}
    />
  );
};
AdminAiTranslateModal.propTypes = {
  /**
   * The ref for the modal
   */
  mainElementRef: PropTypes.object,
  /**
   * The function called to set the open slide state
   */
  setOpenSlide: PropTypes.func,
  /**
   * Flag to determine if the modal is open
   */
  openSlide: PropTypes.bool,
};

export default AdminAiTranslateModal;
