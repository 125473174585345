import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { handleOnAsyncError, handleOnAsyncSuccess } from "utils/helpers";
import { updateBusinessSettingsAsync } from "redux/actions/businessAction";
import { isEqual } from "lodash";

import PageTitle from "components/elements/page-title/PageTitle";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import Switch from "components/forms/switch/Switch";

import usePageEvents from "utils/hooks/useMixPanelEvents";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import { STORE_NAMES } from "utils/constants/redux";
import { QUERY_PARAMS } from "utils/constants/routes";
import { ROLES } from "utils/constants/global";

import "./AdminBusinessSettings.scss";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "../../../../../components/admin/forms/input-control/InputControl";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const SETTINGS_OPTIONS = {
  general: {
    goLive: { label: "goLive", isAdmin: false, type: "Boolean" },
    chat: { label: "chat", isAdmin: false, type: "Boolean" },
    showInBusinessCatalogue: {
      label: "showInBusinessCatalogue",
      isAdmin: true,
      type: "Boolean",
    },
  },
  sound: {
    orderSound: { label: "orderSound", isAdmin: false, type: "Boolean" },
    askForBillSound: {
      label: "askForBillSound",
      isAdmin: false,
      type: "Boolean",
    },
    callWaiterSound: {
      label: "callWaiterSound",
      isAdmin: false,
      type: "Boolean",
    },
  },
  menu: {
    menuItemCalorie: {
      label: "menuItemCalorie",
      isAdmin: false,
      type: "Boolean",
    },
    menuItemTime: { label: "menuItemTime", isAdmin: false, type: "Boolean" },
    menuItemAmount: {
      label: "menuItemAmount",
      isAdmin: false,
      type: "Boolean",
    },
  },
  action: {
    isCallWaiterEnabled: {
      label: "isCallWaiterEnabled",
      isAdmin: false,
      type: "Boolean",
    },
    isAskForBillEnabled: {
      label: "isAskForBillEnabled",
      isAdmin: false,
      type: "Boolean",
    },
  },
  reservation: {
    reservationVRLink: {
      label: "reservationVRLink",
      isAdmin: true,
      type: "String",
    },
    reservation: { label: "reservation", isAdmin: false, type: "Boolean" },
    reservationVREnabled: {
      label: "reservationVREnabled",
      isAdmin: false,
      type: "Boolean",
    },
    reservationMapEnabled: {
      label: "reservationMapEnabled",
      isAdmin: false,
      type: "Boolean",
    },
  },
};

const AdminBusinessSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { saveEvent } = usePageEvents();
  let [searchParams, setSearchParams] = useSearchParams();
  const [hasUnSavedChanges, setHasUnSavedChanges] = useState(false);

  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const isLoadingUpdateBusiness = useSelector(
    (state) => state[STORE_NAMES.business].thunkAPIStates?.updateBusiness
  );
  const user = useSelector((state) => state[STORE_NAMES.user].user);

  const isAdminRole = user.roles[0].name === ROLES.admin.name;

  const [formData, setFormData] = useState({
    [SETTINGS_OPTIONS.general.goLive.label]: business?.goLive,
    [SETTINGS_OPTIONS.general.chat.label]: business?.chat,
    [SETTINGS_OPTIONS.general.showInBusinessCatalogue.label]:
      business?.showInBusinessCatalogue,
    [SETTINGS_OPTIONS.menu.menuItemCalorie.label]: business?.menuItemCalorie,
    [SETTINGS_OPTIONS.menu.menuItemTime.label]: business?.menuItemTime,
    [SETTINGS_OPTIONS.menu.menuItemAmount.label]: business?.menuItemAmount,
    [SETTINGS_OPTIONS.sound.orderSound.label]: business?.orderSound,
    [SETTINGS_OPTIONS.sound.askForBillSound.label]: business?.askForBillSound,
    [SETTINGS_OPTIONS.sound.callWaiterSound.label]: business?.callWaiterSound,
    [SETTINGS_OPTIONS.action.isAskForBillEnabled.label]:
      business?.isAskForBillEnabled,
    [SETTINGS_OPTIONS.action.isCallWaiterEnabled.label]:
      business?.isCallWaiterEnabled,
    [SETTINGS_OPTIONS.reservation.reservationVRLink.label]:
      business?.reservationVRLink,
    [SETTINGS_OPTIONS.reservation.reservation.label]: business?.reservation,
    [SETTINGS_OPTIONS.reservation.reservationVREnabled.label]:
      business?.reservationVREnabled,
    [SETTINGS_OPTIONS.reservation.reservationMapEnabled.label]:
      business?.reservationMapEnabled,
  });

  const previousBusinessUpdateSettings = useMemo(() => {
    return {
      [SETTINGS_OPTIONS.general.goLive.label]: business?.goLive,
      [SETTINGS_OPTIONS.general.chat.label]: business?.chat,
      [SETTINGS_OPTIONS.general.showInBusinessCatalogue.label]:
        business?.showInBusinessCatalogue,
      [SETTINGS_OPTIONS.menu.menuItemCalorie.label]: business?.menuItemCalorie,
      [SETTINGS_OPTIONS.menu.menuItemTime.label]: business?.menuItemTime,
      [SETTINGS_OPTIONS.menu.menuItemAmount.label]: business?.menuItemAmount,
      [SETTINGS_OPTIONS.sound.orderSound.label]: business?.orderSound,
      [SETTINGS_OPTIONS.sound.askForBillSound.label]: business?.askForBillSound,
      [SETTINGS_OPTIONS.sound.callWaiterSound.label]: business?.callWaiterSound,
      [SETTINGS_OPTIONS.action.isAskForBillEnabled.label]:
        business?.isAskForBillEnabled,
      [SETTINGS_OPTIONS.action.isCallWaiterEnabled.label]:
        business?.isCallWaiterEnabled,
      [SETTINGS_OPTIONS.reservation.reservationVRLink.label]:
        business?.reservationVRLink,
      [SETTINGS_OPTIONS.reservation.reservation.label]: business?.reservation,
      [SETTINGS_OPTIONS.reservation.reservationVREnabled.label]:
        business?.reservationVREnabled,
      [SETTINGS_OPTIONS.reservation.reservationMapEnabled.label]:
        business?.reservationMapEnabled,
    };
  }, [business]);

  const handleOnSwitchSettings = (key) => {
    setFormData({
      ...formData,
      [key]: !formData[key],
    });
  };
  const handleChangeInputText = (e, key) => {
    setFormData({
      ...formData,
      [key]: e.target.value === "" ? null : e.target.value,
    });
  };

  const handleOnSave = async () => {
    const response = await dispatch(
      updateBusinessSettingsAsync({ business: formData, id: business.id })
    );
    if (response.error) {
      handleOnAsyncError(t(commonAsyncErrorMessage));
    } else {
      handleOnAsyncSuccess(t("toastMessages.success.updateBusinessSettings"));
      saveEvent("Business Settings Updated");
    }
  };

  useEffect(() => {
    const unsavedChanges = !isEqual(formData, previousBusinessUpdateSettings);

    setHasUnSavedChanges(unsavedChanges);
    if (unsavedChanges) {
      setSearchParams({ ...searchParams, [QUERY_PARAMS.unsavedChanges]: true });
    } else {
      searchParams.delete(QUERY_PARAMS.unsavedChanges);
      setSearchParams(searchParams);
    }
  }, [formData, previousBusinessUpdateSettings]);

  useEffect(() => {
    if (formData?.reservation === false) {
      setFormData((prevData) => ({
        ...prevData,
        reservationMapEnabled: false,
        reservationVREnabled: false,
      }));
      SETTINGS_OPTIONS.reservation.reservationMapEnabled.isDisabled = true;
      SETTINGS_OPTIONS.reservation.reservationVREnabled.isDisabled = true;
    } else {
      SETTINGS_OPTIONS.reservation.reservationMapEnabled.isDisabled = false;
      SETTINGS_OPTIONS.reservation.reservationVREnabled.isDisabled = false;
    }

    if (formData?.reservationVRLink === null) {
      setFormData((prevData) => ({
        ...prevData,
        reservationVREnabled: false,
      }));
      SETTINGS_OPTIONS.reservation.reservationVREnabled.isDisabled = true;
    }
  }, [formData?.reservation, formData?.reservationVRLink]);

  const SettingsSwitch = ({
    label,
    isChecked = false,
    onChange,
    isDisabled,
  }) => (
    <div className="AdminBusinessSettingsSwitch">
      <h5 className="Medium">{label}</h5>
      <div className="SwitchInfo">
        <Switch
          isDisabled={isDisabled}
          isChecked={isChecked}
          onChange={onChange}
        />
        <h6 className={`Medium h7 ${isChecked ? "isActive" : ""}`}>
          {isChecked ? "Active" : "Inactive"}
        </h6>
      </div>
    </div>
  );

  return (
    <div className="AdminBusinessSettings">
      <PageTitle title={t("navbarRoutes.pageTitles.settings")}>
        {hasUnSavedChanges && (
          <PrimaryButton
            text={t("buttons.save")}
            onClick={handleOnSave}
            isLoading={isLoadingUpdateBusiness}
            className="AdminBusinessSettingsSaveButton"
          />
        )}
      </PageTitle>
      <div className="AdminBusinessSettingsInfo">
        {Object.entries(SETTINGS_OPTIONS).map(([category, options]) => (
          <div key={category} className="AdminBusinessSettingsCategory">
            <h2 className="AdminBusinessSettingsCategoryTitle SemiBold">
              {t(`business.settings.${category}`)}
            </h2>
            {Object.entries(options).map(([key, option]) => {
              if (!isAdminRole && option.isAdmin) {
                return null;
              }
              return option.type === "Boolean" ? (
                <SettingsSwitch
                  key={key}
                  optionKey={key}
                  label={t(`business.settings.${option.label}`)}
                  isChecked={formData[key]}
                  onChange={() => handleOnSwitchSettings(key)}
                  isDisabled={option?.isDisabled}
                />
              ) : option.type === "String" ? (
                <InputControl
                  key={key}
                  type="text"
                  name={key}
                  labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
                  value={formData[key] || ""}
                  onChange={(e) => handleChangeInputText(e, key)}
                  placeholder={t(`business.settings.${option.label}`)}
                />
              ) : null;
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminBusinessSettings;
