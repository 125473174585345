import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css/effect-fade";
import "swiper/css";

import {
  updateBasket,
  updateFavorites,
  updateOrderItemByIndex,
} from "redux/slices/basketStore";
import {
  calculateMenuItemPrice,
  findItemById,
  calculateMenuItemPriceBySchedule,
} from "utils/general";
import MenuItemHeader from "components/elements/menu-item-header/MenuItemHeader";
import MenuItemInfo from "components/elements/menu-item-info/MenuItemInfo";
import CTAButton, {
  ENUMS as CTA_ENUMS,
} from "components/buttons/cta-button/CTAButton";
import ModificationsMenu from "components/elements/modifications-menu/ModificationsMenu";
import {
  findGuest,
  findMenuItemByIdAndPublished,
  getBasketModificationsWithDefaultValues,
} from "utils/helpers";
import { ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import useLanguage from "utils/hooks/useLanguage";
import { createDOBucketName } from "utils/DO-Spaces";
import AnimatedPlusMinusButton from "components/buttons/animated-plus-minus-button/AnimatedPlusMinusButton";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import WelcomeClient from "components/welcome-client/WelcomeClient";

import "./MenuItem.scss";

const MenuItem = () => {
  const { displayDataByLanguage } = useLanguage();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { businessId, menuItemId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const basketIndex = queryParams.get("basketIndex");
  const isEditMode = queryParams.get("isEditMode");

  const userId = useSelector((state) => state[STORE_NAMES.guest].id);
  const basketOrders = useSelector((state) => state[STORE_NAMES.basket].order);
  const guest = findGuest(userId, basketOrders);

  const favoriteItems = guest?.favoriteItems || [];

  const orderItems = guest?.orderItems || [];

  const menu = useSelector((state) => state[STORE_NAMES.menu].data);
  const isLoading = useSelector((state) => state[STORE_NAMES.menu].isLoading);
  const item =
    menu &&
    (basketIndex
      ? findMenuItemByIdAndPublished(
          menu.categories,
          orderItems[basketIndex].item,
          dispatch,
          guest.id
        )
      : findItemById(menuItemId, menu));

  const menuItem = {
    ...item,
    coverImageSrc: createDOBucketName(item.coverImageSrc),
    otherImagesSrc: item.otherImagesSrc?.map((otherImageSrc) =>
      createDOBucketName(otherImageSrc)
    ),
  };
  const allModifications = menu && findItemById(menuItemId, menu).modifications;
  const navigate = useNavigate();
  const [count, setCount] = useState(
    basketIndex ? orderItems[basketIndex].count : 1
  );
  const discountPrice = calculateMenuItemPriceBySchedule(menuItem);
  const menuItemPrice = menuItem.priceSell;

  const [selectedModifications, setSelectedModifications] = useState([]);
  const [finalPrice, setFinalPrice] = useState(0);

  const [focusedModificationOption, setFocusedModificationOption] =
    useState(null);
  useEffect(() => {
    if (menu) {
      const defaultModifications = basketIndex
        ? menuItem.modifications
        : getBasketModificationsWithDefaultValues(menuItem.modifications);
      setSelectedModifications(defaultModifications);
    }
  }, [menu]);

  useEffect(() => {
    if (menu) {
      setFinalPrice(
        calculateMenuItemPrice({
          ...menuItem,
          priceSell: menuItemPrice + discountPrice,
          modifications: selectedModifications,
        })
      );
    }
  }, [selectedModifications, menu]);

  if (isLoading) {
    return <WelcomeClient />;
  }

  if (!menuItem.isPublished) {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
    );
  }

  const handleOnFavorite = () => {
    dispatch(
      updateFavorites({
        userId,
        menuItemID: menuItem.id,
      })
    );
  };

  const handleGoBack = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
    );
  };

  const handleOnMinus = () => {
    count > 1 && setCount((prev) => --prev);
  };

  const handleOnPlus = () => {
    setCount((prev) => ++prev);
  };

  const handleAddToBasket = () => {
    const filteredModifications = selectedModifications.filter(
      (modification) => {
        return modification.options.length !== 0;
      }
    );
    dispatch(
      updateBasket({
        menuItem: {
          id: menuItem.id,
          modifications: filteredModifications.map((modification) => {
            return {
              id: modification.id,
              options: modification.options.map((option) => {
                return {
                  id: option.id,
                  count: option.count ?? 1,
                };
              }),
            };
          }),
        },
        count: count,
        userId,
      })
    );
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
    );
  };

  const handleSaveChanges = () => {
    dispatch(
      updateOrderItemByIndex({
        menuItem: {
          ...menuItem,
          modifications: selectedModifications,
        },
        count: count,
        userId,
        basketIndex: parseInt(basketIndex),
      })
    );
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.basket}`
    );
  };

  const menuItemName = displayDataByLanguage(menuItem.name);

  return (
    <div className="MenuItem">
      <MenuItemHeader
        onFavorite={handleOnFavorite}
        itemId={menuItem.id}
        onGoBack={handleGoBack}
        favoriteItems={favoriteItems}
      />

      {menuItem.otherImagesSrc?.length > 1 ? (
        <Swiper
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          loop={true}
          effect={"fade"}
          modules={[EffectFade, Autoplay]}
          className="MenuItemSlider"
        >
          {menuItem.otherImagesSrc.map((imageSrc, index) => (
            <SwiperSlide key={index}>
              <img
                className="MenuItemCarouselImage"
                src={imageSrc}
                alt={menuItemName}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <ImageWithPlaceholder
          imageSource={menuItem.coverImageSrc}
          placeholder={IMAGE_ITEM_PLACEHOLDER}
          alt={menuItem.id}
          className="MenuItemCoverImage"
        />
      )}

      <div className="MenuItemInfoContainer">
        <h2 className="SemiBold">{menuItemName}</h2>
        <MenuItemInfo
          onMinus={handleOnMinus}
          onPlus={handleOnPlus}
          menuItem={menuItem}
          count={count}
          discountPrice={discountPrice}
          menuItemPrice={menuItemPrice}
          disableMinusButtonAtOne
        />
      </div>
      {allModifications.length > 0 && (
        <div className="MenuItemModifications">
          {allModifications.map((modification) => (
            <ModificationsMenu
              key={modification.id}
              data={modification}
              selectedOptions={selectedModifications.find(
                (options) => options.id === modification.id
              )}
              setSelectedModifications={setSelectedModifications}
              focusedModificationOption={focusedModificationOption}
              setFocusedModificationOption={setFocusedModificationOption}
            />
          ))}
        </div>
      )}
      <div className="MenuItemFooter">
        <AnimatedPlusMinusButton
          onMinusClick={handleOnMinus}
          onPlusClick={handleOnPlus}
          hasBorder
          count={count}
          doAnimate
          disableMinusButtonAtOne
        />
        {isEditMode ? (
          <CTAButton
            className="SaveChanges"
            onClick={handleSaveChanges}
            name={t("buttons.saveChanges")}
            type={CTA_ENUMS.types.TYPE_N}
            price={count * finalPrice}
          />
        ) : (
          <CTAButton
            className="AddToBasketBtn"
            onClick={handleAddToBasket}
            name={t("buttons.addBasket")}
            type={CTA_ENUMS.types.TYPE_N}
            price={count * finalPrice}
          />
        )}
      </div>
    </div>
  );
};

export default MenuItem;
