import { createSlice } from "@reduxjs/toolkit";

import { STORE_NAMES } from "utils/constants/redux";
import { getLanguageImage } from "utils/helpers";
import { getGeneralMetaDataAsync } from "../actions/metaDataAction";

const initialState = {
  value: 0,
  isDarkMode: false,
  reduxVersion: 0,
  languages: [],
  units: [],
  plans: [],
  hasUnSavedChanges: false,
  currencies: [],
  isAdminDashboardPinLocked: false,
  enums: {},
};

export const appStore = createSlice({
  name: STORE_NAMES.app,
  initialState: initialState,
  reducers: {
    setDarkMode: (state, action) => {
      return { ...state, isDarkMode: action.payload };
    },
    setHasUnSavedChanges: (state, action) => {
      return { ...state, hasUnSavedChanges: action.payload };
    },
    setLanguage: (state, { payload }) => {
      return { ...state, language: payload };
    },
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // state.value += 1;
      return { ...state, value: state.value + 1 };
    },
    decrement: (state) => {
      return { ...state, value: state.value - 1 };
    },
    incrementByAmount: (state, action) => {
      return { ...state, value: state.value + action.payload };
    },
    updateReduxVersion: (state, action) => {
      const reduxVersion = action.payload;
      return { ...state, reduxVersion };
    },
    resetAppStore: () => {
      return { ...initialState };
    },
    setIsAdminDashboardPinLocked: (state, action) => {
      return { ...state, isAdminDashboardPinLocked: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGeneralMetaDataAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        units: payload.units,
        plans: payload.plans,
        currencies: payload.currencies,
        languages: payload.languages.map((language) => ({
          ...language,
          imgSrc: getLanguageImage(language.code),
        })),
        enums: payload.allEnums,
      };
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setDarkMode,
  setHasUnSavedChanges,
  increment,
  decrement,
  incrementByAmount,
  resetAppStore,
  updateReduxVersion,
  setIsAdminDashboardPinLocked,
} = appStore.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementThunk = (amount) => (dispatch, getState, myArgs) => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount + 1));
    setTimeout(() => {
      dispatch(decrement());
      dispatch(decrement());
    }, 1000);
  }, 1000);
};

export default appStore.reducer;
