import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import useAsync from "utils/hooks/useAsync";
import { CHART_PERIODS } from "components/buttons/chart-period-select-button/ChartPeriodSelectButton";
import { STORE_NAMES } from "utils/constants/redux";
// import AreaChart from "components/admin/charts/area-chart/AreaChart";
import VerticalBarChart from "components/admin/charts/vertical-bar-chart/VerticalBarChart";
import DonutChart from "components/admin/charts/donut-chart/DonutChart";
import StatsCardChartWithPeriodWrapper from "components/admin/charts/chart-wrappers/stat-chart-with-periods-wrapper/StatsCardChartWithPeriodWrapper";
import StatsNumberDataWrapper from "components/admin/charts/chart-wrappers/stats-number-data-wrapper/StatsNumberDataWrapper";
import StatsCardChartWrapper from "components/admin/charts/chart-wrappers/stats-card-chart-wrapper/StatsCardChartWrapper";
import { getStatsOrderSale } from "utils/api/services/dashboard";
import {
  fixCharDataWidth,
  formatChartDailyData,
  formatChartWeeklyData,
  formatOrderChartData,
} from "utils/helper-functions/dashboardStatsHelper";
import EmptyState from "components/admin/empty-state/EmptyState";
import Spinner from "components/elements/spinner/Spinner";
import EmptyChartDataIcon from "assets/icons/admin-dashboard/EmptyChartDataIcon.svg";

import "./AdminDashSalesStats.scss";

const CHART_DATA_COLORS = {
  saleFillColor: "#FFDB7D",
  saleStrokeColor: "#FFCA40",
  revenueFillColor: "#8D8D8D",
  revenueStrokeColor: "#676767",
};

const getOrderSaleData = (
  fetchedRawData,
  selectedPeriod,
  fromDateToDate,
  t
) => {
  const CHART_DATA_KEYS = {
    sale: "sale",
    revenue: "revenue",
    salesData: "salesData",
    xAxisDataKey: selectedPeriod,
  };
  const { sale, revenue, salesData, xAxisDataKey } = CHART_DATA_KEYS;

  const fromDate = new Date(fromDateToDate.from);
  const toDate = new Date(fromDateToDate.to);

  let data = formatOrderChartData(
    fetchedRawData,
    salesData,
    selectedPeriod,
    xAxisDataKey,
    sale,
    revenue,
    true
  );

  if (selectedPeriod === CHART_PERIODS.daily) {
    data = formatChartDailyData(data, fromDate, toDate, t);
  } else if (selectedPeriod === CHART_PERIODS.weekly) {
    data = formatChartWeeklyData(data, sale, revenue, t);
  }

  return {
    meta: {
      type: "monotone",
      xAxisDataKey: xAxisDataKey,
      data: [
        {
          dataKey: sale,
          legendName: t("dashboard.legends.sale"),
          fillColor: CHART_DATA_COLORS.saleFillColor,
          strokeColor: CHART_DATA_COLORS.saleStrokeColor,
          stackId: "1",
        },
        {
          dataKey: revenue,
          legendName: t("dashboard.legends.revenue"),
          fillColor: CHART_DATA_COLORS.revenueFillColor,
          strokeColor: CHART_DATA_COLORS.revenueStrokeColor,
          stackId: "2",
        },
      ],
    },
    data,
  };
};

const getStatNumberData = (fetchedRawData, t, currencySymbol) => {
  return [
    {
      title: t("dashboard.orderStatistics.totalSale"),
      amount: `${currencySymbol}${
        isNaN(parseFloat(fetchedRawData?.total))
          ? 0
          : parseFloat(fetchedRawData?.total).toFixed(2)
      }`,
    },

    {
      title: t("dashboard.orderStatistics.revenue"),
      amount: `${currencySymbol}${
        isNaN(fetchedRawData?.total / fetchedRawData?.orderCount)
          ? 0
          : parseFloat(
              (fetchedRawData?.total - fetchedRawData?.costTotal).toFixed(2)
            )
      }`,
    },
    {
      title: t("dashboard.orderStatistics.averageBill"),
      amount: `${currencySymbol}${
        isNaN(fetchedRawData?.total / fetchedRawData?.orderCount)
          ? 0
          : parseFloat(
              (fetchedRawData?.total / fetchedRawData?.orderCount).toFixed(2)
            )
      }`,
    },
  ];
};

const getDonutChartData = (fetchedRawSalesData, t, paymentMethods) => {
  return fetchedRawSalesData?.paymentMethods.map((data, index) => {
    // Handle special case for POS_TERMINAL
    const formattedMethodName =
      data.methodName === paymentMethods["pos_terminal"]
        ? "POSTerminal"
        : data.methodName.toLowerCase();

    const gradientId = `gradient${index + 1}`;
    const name = t(`payment.${formattedMethodName}`);
    const content = name;
    return {
      name,
      gradientId,
      value: data.value,
      content,
    };
  });
};

const AdminDashSalesStats = () => {
  const { setTitle, fromDateToDate } = useOutletContext();
  const [selectedPeriod, setSelectedPeriod] = useState(CHART_PERIODS.hourly);
  const { t } = useTranslation();
  let chartDataWidth = "100%";

  useEffect(() => {
    setTitle(t("navbarRoutes.pageTitles.salesStatistics"));
  }, [t]);

  const businessId = useSelector(
    (state) => state[STORE_NAMES.user].user?.business.id
  );

  const currency = useSelector(
    (state) => state[STORE_NAMES.menu]?.data?.currency
  );

  const { paymentMethods } = useSelector(
    (state) => state[STORE_NAMES.app].enums
  );

  const { result: fetchedRawSalesData, loading: orderStatsSaleLoading } =
    useAsync(getStatsOrderSale, {
      immediate: true,
      params: [businessId, fromDateToDate.from, fromDateToDate.to],
    });

  const statsNumberData = getStatNumberData(
    fetchedRawSalesData,
    t,
    currency?.symbol
  );

  const statsOrderSaleData = getOrderSaleData(
    fetchedRawSalesData,
    selectedPeriod,
    fromDateToDate,
    t
  );

  const statsDonutChartData = getDonutChartData(
    fetchedRawSalesData,
    t,
    paymentMethods
  );

  switch (selectedPeriod) {
    case CHART_PERIODS.hourly:
      chartDataWidth = "195%";

      break;
    case CHART_PERIODS.daily:
      chartDataWidth = fixCharDataWidth(
        statsOrderSaleData?.data?.length,
        12,
        10
      );
      break;
    case CHART_PERIODS.weekly:
      chartDataWidth = fixCharDataWidth(
        statsOrderSaleData?.data?.length,
        6,
        15
      );
      break;
    case CHART_PERIODS.monthly:
      break;
    default:
      chartDataWidth = "100%";
  }
  const controlAreaChartData = statsOrderSaleData?.data.every(
    (day) => day.sale === 0 && day.revenue === 0
  );

  const controlDonutChartData = statsDonutChartData?.length > 0;

  return (
    <div className="AdminDashSalesStats">
      {orderStatsSaleLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>
          <StatsNumberDataWrapper data={statsNumberData} />

          <StatsCardChartWithPeriodWrapper
            activeButton={selectedPeriod}
            setActiveButton={setSelectedPeriod}
            title={t("dashboard.totalSales")}
            legendData={statsOrderSaleData?.meta?.data}
            disableMonthlyButton={true}
            chartToRender={
              statsOrderSaleData.data ? (
                !controlAreaChartData ? (
                  <VerticalBarChart
                    chartData={statsOrderSaleData}
                    chartWidth={chartDataWidth}
                  />
                ) : (
                  <EmptyState
                    icon={EmptyChartDataIcon}
                    isAdmin={true}
                    description={t("dashboard.noResultForThisPeriodMsg")}
                    descriptionClassName={
                      "AdminSalesDashboardEmptyStateDescription"
                    }
                  />
                )
              ) : (
                <div>
                  <Spinner />
                </div>
              )
            }
          />

          <div className="AdminDashboardChartsDonutChart">
            <StatsCardChartWrapper
              title={t("dashboard.paymentMethodAnalysis")}
              subTitle={fromDateToDate}
              className="StatsCardChartWrapperDonutChart"
              chartToRender={
                !orderStatsSaleLoading ? (
                  controlDonutChartData ? (
                    <DonutChart
                      title={t("dashboard.paymentMethodAnalysis")}
                      data={statsDonutChartData}
                    />
                  ) : (
                    <EmptyState
                      icon={EmptyChartDataIcon}
                      isAdmin={true}
                      description={t("dashboard.noResultForThisPeriodMsg")}
                      descriptionClassName={
                        "AdminSalesDashboardEmptyStateDescription"
                      }
                      className={"AdminDashboardChartsEmptyState"}
                    />
                  )
                ) : (
                  <div>
                    <Spinner />
                  </div>
                )
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AdminDashSalesStats;
