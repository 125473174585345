import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import isEqual from "lodash/isEqual";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import IconButton from "components/buttons/icon-button/IconButton";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import DeleteButton from "components/buttons/delete-button/DeleteButton";
import Modal from "components/modal/Modal";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import Switch from "components/forms/switch/Switch";
import Language from "components/admin/elements/language/Language";
import { getActiveLanguageValue, getInitialSchedule } from "utils/helpers";
import { STORE_NAMES } from "utils/constants/redux";
import DateAccordion from "components/admin/elements/date-accordion/DateAccordion";
import Confirm, { ENUMS as ENUMS_CONFIRM } from "../confirm/Confirm";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useFormOutsideClickHandler from "utils/hooks/useFormOutsideClickHandler";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { zodResolver } from "@hookform/resolvers/zod";

import "./AddItemModal.scss";

export const ENUMS = {
  name: "AddItem",
};

const AddItemModal = ({
  mainElementRef,
  setOpenSlide,
  title,
  placeholder,
  item,
  hasPublishMode,
  openSlide,
  onSave,
  onDelete,
  isMultiLanguage,
  hasSchedule,
  setOutsideClickAction,
  isLoading,
  mapEditZone,
}) => {
  const { t } = useTranslation();

  const [
    openSlideConfirmCloseModal,
    setOpenSlideConfirmCloseModal,
    mainElementRefConfirmCloseModal,
  ] = useOutsideClick();

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [activeLanguageCode, setActiveLanguageCode] = useState(null);
  const { data: menu } = useSelector((state) => state[STORE_NAMES.menu]);
  const menuLanguage = menu?.language;
  const isEditable = !!item;
  const initialSchedule = item?.schedule?.from
    ? item?.schedule
    : getInitialSchedule(new Date());
  const initialData = {
    name: isMultiLanguage
      ? [{ value: "", languageCode: menuLanguage.code }]
      : "",
    isPublished: true,
    schedule: initialSchedule,
  };
  const [formData, setFormData] = useState(initialData);
  const [formDataInitial, setFormDataInitial] = useState(initialData);

  useFormOutsideClickHandler({
    formData,
    formDataInitial,
    setOpenSlide,
    setOpenSlideConfirmCloseModal,
    setOutsideClickAction,
  });

  useEffect(() => {
    if (item) {
      setFormData({
        name: item.name,
        isPublished: item.isPublished,
        schedule: item.schedule?.from ? item.schedule : initialSchedule,
      });
      setFormDataInitial({
        name: item.name,
        isPublished: item.isPublished,
        schedule: item.schedule?.from ? item.schedule : initialSchedule,
      });
      setActiveLanguageCode(isMultiLanguage ? item.name[0].languageCode : null);
      setIsAccordionOpen(item.schedule?.isActive || false);
    } else {
      setFormData(initialData);
      setFormDataInitial(initialData);
      setActiveLanguageCode(menuLanguage.code);
      setIsAccordionOpen(initialSchedule.isActive);
    }

    reset();
  }, [item, openSlide]);

  const schemas = useValidationSchema(t);
  const { addItemModalSchema, requiredStringSchema } = schemas;

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
  } = useForm({
    criteriaMode: "all",
    resolver: zodResolver(addItemModalSchema),
  });

  useEffect(() => {
    reset({
      formData,
    });
  }, [reset, formData]);

  const handleOnInputChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      name: isMultiLanguage
        ? formData.name.map((property) => {
            if (property.languageCode === activeLanguageCode) {
              return {
                ...property,
                value: value,
              };
            }
            return property;
          })
        : value,
    });
  };

  const handleOnModalClose = () => {
    if (!isEqual(formData, formDataInitial)) {
      return setOpenSlideConfirmCloseModal(true);
    }
    setOpenSlide(false);
  };

  const handleOnPublishModeChange = () => {
    setFormData({
      ...formData,
      isPublished: !formData.isPublished,
    });
  };

  const handleNameInputWithMultipleLanguage = () => {
    const nameError = formData.name
      .map((name) => {
        try {
          requiredStringSchema.parse(name.value);
          return null;
        } catch (error) {
          setError("name", {
            type: "manual",
            message: t("errorMessages.input.generalRequired"),
          });

          setActiveLanguageCode(name.languageCode);
          return name.languageCode;
        }
      })
      .filter((name) => name !== null);

    return nameError;
  };

  const handleOnSubmit = () => {
    if (isMultiLanguage) {
      const nameError = handleNameInputWithMultipleLanguage();

      if (nameError.length > 0) {
        return;
      }
    }

    const hasNoSchedule = isEqual(formData.schedule, initialSchedule);
    const updatedFormData = { ...formData };
    if (hasNoSchedule) {
      delete updatedFormData.schedule;
    }
    if (isEditable) {
      onSave(
        {
          ...updatedFormData,
          ...(updatedFormData?.schedule && {
            schedule: {
              ...updatedFormData.schedule,
              weekdays: JSON.stringify(updatedFormData.schedule.weekdays),
            },
          }),
        },
        item.id
      );
    } else {
      onSave({
        ...updatedFormData,
        ...(updatedFormData?.schedule && {
          schedule: {
            ...updatedFormData.schedule,
            weekdays: JSON.stringify(updatedFormData.schedule.weekdays),
          },
        }),
      });
    }
  };

  const setFormDataName = (properties) => {
    const newProperties = properties.map((property) => {
      property.filter((item) => !item.value);
      return property.filter((item) => item.value !== null);
    });
    setFormData({ ...formData, name: newProperties[0] });
  };

  const handleOnCancelCloseModal = () => {
    setOpenSlideConfirmCloseModal(false);
  };

  const handleOnConfirmCloseModal = () => {
    setOpenSlide(false);
    setOpenSlideConfirmCloseModal(false);
  };

  const ModalBodyContent = (
    <InputControl
      type="text"
      required
      name="name"
      placeholder={`${placeholder} `} //${t("inputs.name")}
      labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
      value={
        isMultiLanguage
          ? getActiveLanguageValue(formData.name, activeLanguageCode)
          : formData.name
      }
      className="AddItemModalBodyFormInput"
      hasError={errors.name}
      error={
        <ErrorMessage
          errors={errors}
          name="name"
          render={({ message }) => (
            <p className="h7 error-message">{message}</p>
          )}
        />
      }
      func={{
        ...register("name", {
          onChange: handleOnInputChange,
        }),
      }}
    />
  );
  const AddItemModalHeader = (
    <div className="AddItemModalHeader">
      <h3 className="SemiBold AddItemModalHeaderTitle">
        {isEditable
          ? t("buttons.editForModal", { title: title })
          : t("buttons.addForModal", { title: title })}
      </h3>
      {hasPublishMode && (
        <div className="AddItemModalHeaderTitlePublishMode">
          <h6 className="h7">
            {formData.isPublished
              ? t("common.published")
              : t("common.unPublished")}
          </h6>
          <Switch
            isChecked={formData.isPublished}
            onChange={handleOnPublishModeChange}
          />
        </div>
      )}
      {isEditable && (
        <DeleteButton
          setOpenSlide={setOpenSlide}
          onClick={() => onDelete(item.id)}
        />
      )}
      <IconButton onClick={handleOnModalClose} svgComponent={<IconClose />} />
    </div>
  );

  const AddItemModalBody = (
    <div className="AddItemModalBody">
      <form
        className="AddItemModalBodyForm"
        onSubmit={(e) => e.preventDefault()}
      >
        {isMultiLanguage ? (
          <Language
            content={ModalBodyContent}
            activeLanguageCode={activeLanguageCode}
            setActiveLanguageCode={setActiveLanguageCode}
            properties={[formData.name]}
            setProperties={setFormDataName}
          ></Language>
        ) : (
          ModalBodyContent
        )}
        {hasSchedule && (
          <div className="AddItemModalBodyFormSchedule">
            <DateAccordion
              isAccordionOpen={isAccordionOpen}
              setIsAccordionOpen={setIsAccordionOpen}
              formData={formData}
              setFormData={setFormData}
            />
          </div>
        )}
        {mapEditZone && (
          <div className="AddItemModalBodyFormInputs">
            <InputControl
              type="number"
              placeholder="Width"
              name="width"
              hasError={errors.password}
              labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
              definitionText="m"
              error={
                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
            />
            <InputControl
              type="number"
              placeholder="Height"
              name="height"
              hasError={errors.password}
              labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
              definitionText="m"
              error={
                <ErrorMessage
                  errors={errors}
                  name="height"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
            />
          </div>
        )}
      </form>
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_C}
        title={t("modal.warningModalTitleUnsavedChanges")}
        mainElementRefConfirm={mainElementRefConfirmCloseModal}
        onCancel={(e) => handleOnCancelCloseModal(e)}
        onConfirm={(e) => handleOnConfirmCloseModal(e)}
        openSlide={openSlideConfirmCloseModal}
        description={t("modal.warningModalDescription")}
      />
    </div>
  );

  const AddItemModalFooter = (
    <PrimaryButton
      onClick={handleSubmit(handleOnSubmit)}
      text={t("buttons.save")}
      isLoading={isLoading}
    />
  );

  return (
    <Modal
      header={AddItemModalHeader}
      body={AddItemModalBody}
      footer={AddItemModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    ></Modal>
  );
};

AddItemModal.propTypes = {
  /**
   * The ref for the modal
   */
  mainElementRef: PropTypes.object,

  /**
   * The function called to set the open slide state
   */
  setOpenSlide: PropTypes.func,

  /**
   * The title to display
   */
  title: PropTypes.string.isRequired,

  placeholder: PropTypes.string,

  /**
   * The item to display
   */
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    isPublished: PropTypes.bool,
    schedule: PropTypes.object,
    value: PropTypes.string,
  }),

  /**
   * Determines if the component has 'publish' mode
   */
  hasPublishMode: PropTypes.bool,

  /**
   * Flag to determine if the modal is open
   */
  openSlide: PropTypes.bool,

  /**
   * The function called when the form is submitted to save the data.
   */
  onSave: PropTypes.func,

  /**
   * The function called to delete the item.
   */
  onDelete: PropTypes.func,

  /**
   * Flag to determine if the item supports multiple languages
   */
  isMultiLanguage: PropTypes.bool,

  /**
   * Flag to determine if the item has a schedule
   */
  hasSchedule: PropTypes.bool,

  /**
   * The function called to set the outside click action
   */
  setOutsideClickAction: PropTypes.func,

  /**
   * Flag to determine if the component is loading
   * */
  isLoading: PropTypes.bool,
  mapEditZone: PropTypes.bool,
};

export default AddItemModal;
